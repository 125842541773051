import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"

export async function createSmartInvite(): Promise<string> {
    const { data, error } = await supabase.from(dbTables.smartInvites).insert({}).select()
    if (error || !data || data.length === 0) {
        throw error
    }
    return data[0].id
}

export async function createSmartInviteWithGameId(gameId: string): Promise<string> {
    const { data, error } = await supabase.from(dbTables.smartInvites).insert({ game_id: gameId }).select()
    if (error || !data || data.length === 0) {
        throw error
    }
    return data[0].id
}

export async function updateSmartInvite(smartInviteId: string, gameId: string) {
    const { error } = await supabase.from(dbTables.smartInvites).update({ game_id: gameId }).eq("id", smartInviteId)
    if (error) {
        throw error
    }
}

export async function destroySmartInvite(smartInviteId: string) {
    const { error } = await supabase.from(dbTables.smartInvites).delete().eq("id", smartInviteId)
    if (error) {
        throw error
    }
}

export async function destroySmartInviteWithGameId(gameId: string) {
    const { error } = await supabase.from(dbTables.smartInvites).delete().eq("game_id", gameId)
    if (error) {
        throw error
    }
}

export async function getCorrespondingGameId(smartInviteId: string): Promise<string | null> {
    const { data, error } = await supabase.from(dbTables.smartInvites).select("game_id").eq("id", smartInviteId)
    if (error || !data || data.length === 0) {
        return null
    }
    return data[0].game_id
}

export async function getCorrespondingSmartInviteId(gameId: string): Promise<string | null> {
    const { data, error } = await supabase.from(dbTables.smartInvites).select("id").eq("game_id", gameId).single()
    if (error || !data) {
        return null
    }
    return data.id
}
