import { Center } from "@mantine/core"
import ButtonWrapper from "../common/ButtonWrapper"
import React from "react"

interface FeedbackButtonProps {
    onClick: () => void
    disabled?: boolean
}

export default function FeedbackButton(props: FeedbackButtonProps) {
    return (
        <Center className={"w-4/5"}>
            <ButtonWrapper onClick={props.onClick} className={"rounded-3xl w-full h-12 text-xl mt-4"} disabled={props.disabled}>
                Submit
            </ButtonWrapper>
        </Center>
    )
}
