import { ReactNode } from "react"

interface HeaderProps {
    children: ReactNode
    size?: number
    center?: boolean
    className?: string
}

export default function Header(props: HeaderProps) {
    return (
        <div className={"w-full mt-6"}>
            <p
                className={`text-white font-bold ${props.center ? "text-center" : ""} ${
                    props.size ? `text-${props.size}xl` : "text-5xl"
                } ${props.className}`}
            >
                {props.children}
            </p>
        </div>
    )
}
