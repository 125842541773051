import Google from "../../assets/icons/google.png"
import AuthenticationMethod from "./AuthenticationMethod"

interface LogInMethodProps {
    googleLogIn: () => void
    isSignUp?: boolean
    className?: string
}

export default function AuthenticationMethods(props: LogInMethodProps) {
    return (
        <div className={`w-2/3 flex flex-col items-center ${props.className}`}>
            <p className={"mb-2 text-white text-lg font-bold"}>Or {props.isSignUp ? "sign up" : "log in"} with</p>
            <div className={"flex flex-row justify-around w-full"}>
                <AuthenticationMethod onClick={props.googleLogIn}>
                    <img src={Google} alt={"Google"} className={"w-8 h-8"} />
                </AuthenticationMethod>
                {/*<AuthenticationMethod onClick={() => {}}>*/}
                {/*    <img src={Apple} alt={"Apple"} className={"w-8 h-8"} />*/}
                {/*</AuthenticationMethod>*/}
                {/*<AuthenticationMethod onClick={() => {}}>*/}
                {/*    <img src={Instagram} alt={"Instagram"} className={"w-8 h-8"} />*/}
                {/*</AuthenticationMethod>*/}
            </div>
        </div>
    )
}
