import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"

export type DisplayLevel = {
    level_number: number
    description: string
    image_name: string
    id: string
    deck_id: string
}

function processResponse(data: any, error: any): DisplayLevel[] | null {
    if (error) {
        console.log("Error while getting levels: " + error)
        return null
    }
    if (!data || data.length === 0) {
        console.log("No levels found")
        return null
    }
    return data.map((level: any) => {
        return {
            level_number: level.level_number,
            description: level.description,
            image_name: level.image_name,
            id: level.id,
            deck_id: level.deck,
        }
    })
}

export async function getAllLevelsFromDBAsDisplayLevels(): Promise<DisplayLevel[] | null> {
    const { data, error } = await supabase.from(dbTables.levels).select("*").order("level_number", { ascending: true })
    return processResponse(data, error)
}

export async function getLevelsFromDBAsDisplayLevels(deckId: string): Promise<DisplayLevel[] | null> {
    const { data, error } = await supabase
        .from(dbTables.levels)
        .select("*")
        .eq("deck", deckId)
        .order("level_number", { ascending: true })
    return processResponse(data, error)
}
