import { IconPlus } from "@tabler/icons"
import { colors } from "../../constants/colors"
import { useNavigate } from "react-router"

export default function GamesHeader() {
    const navigate = useNavigate()

    return (
        <div className={"w-full flex flex-row justify-between items-center"}>
            <p className={"text-white text-5xl font-bold text-center"}>Games</p>
            <IconPlus
                size={33}
                color={colors.accent}
                className={"mt-2 hover:cursor-pointer"}
                onClick={() => navigate("/addGame")}
            />
        </div>
    )
}
