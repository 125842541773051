interface CardImageProps {
    src: string
    alt: string
    onClick?: () => void
    className?: string
}

export default function CardImage(props: CardImageProps) {
    return (
        <img
            src={props.src}
            alt={props.alt}
            className={`w-20 h-20 rounded-[43%] ml-3 select-none ${props.className}`}
            onClick={props.onClick}
        />
    )
}
