export function cardObjectsToIds(cards: any[]) {
    return cards.map((card) => card.id)
}

export function cardObjectsToQuestions(cards: any[]) {
    return cards.map((card) => card.question)
}

export function getCurrentCardIndexFromId(cards: any[], id: string) {
    if (cards.indexOf(id) === -1) return 0
    return cards.indexOf(id)
}

export function getCardsDone(cards: any[], currentCardIndex: number) {
    return cards.slice(0, currentCardIndex)
}

export function getCardsLeft(cards: any[], currentCardIndex: number) {
    return cards.slice(currentCardIndex + 1)
}
