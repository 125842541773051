import RoundIconButton from "./RoundIconButton"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons"
import { colors } from "../../constants/colors"

interface ChevronButtonProps {
    direction: "left" | "right"
    onClick: () => void
    size: "small" | "large"
    disabled?: boolean
}

export default function ChevronButton(props: ChevronButtonProps) {
    return (
        <RoundIconButton onClick={props.onClick} size={props.size} disabled={props.disabled}>
            {props.direction === "left" ? (
                <IconChevronLeft
                    size={props.size === "large" ? 45 : 30}
                    color={colors.white}
                    className={`mr-1 transition-all duration-200 ${props.disabled ? "opacity-50" : "active:scale-95"}`}
                />
            ) : (
                <IconChevronRight
                    size={props.size === "large" ? 45 : 30}
                    color={colors.white}
                    className={`ml-1 transition-all duration-200 ${props.disabled ? "opacity-50" : "active:scale-95"}`}
                />
            )}
        </RoundIconButton>
    )
}
