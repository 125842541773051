import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"

export async function getSelectedCard(levelGameId: string): Promise<string | null> {
    const { data, error } = await supabase.from("levelGame").select("selected_card").eq("id", levelGameId)
    if (error) {
        console.log(`Error getting selected card ${error}`)
        return null
    }
    return data[0].selected_card
}

export async function getLevelGames(gameId: string, deckId: string, levelId: string) {
    const { data, error } = await supabase
        .from(dbTables.levelGames)
        .select("*")
        .eq("game", gameId)
        .eq("deck", deckId)
        .eq("level", levelId)
    if (error) {
        console.log(`Error getting level games ${error}`)
        return null
    }
    return data
}

export async function finishLevelGame(levelGameId: string) {
    const { error } = await supabase
        .from(dbTables.levelGames)
        .update({ finished: true })
        .eq("id", levelGameId)
    if (error) {
        console.log(`Error finishing level game ${error}`)
        return null
    }
    return true
}
