import ChatHeader from "./ChatHeader"
import ChatBody from "./ChatBody"

export default function ChatView() {
    return (
        <div
            className={`w-full max-w-[550px] h-full tablet:rounded-2xl flex flex-col items-center bg-primary-background overflow-hidden`}
        >
            <ChatHeader />
            <ChatBody />
        </div>
    )
}
