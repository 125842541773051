import { defaultImage, userImage } from "../constants/images"

export function getRandom(array: any[], valid: (item: any) => boolean = (_) => true): any {
    const randomItem = array[Math.floor(Math.random() * array.length)]
    if (valid(randomItem)) {
        return randomItem
    } else {
        return getRandom(array, valid)
    }
}

export const validUserImageExceptions = (item: any) => {
    return !(item === defaultImage || item === userImage)
}
