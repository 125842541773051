import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { useMantineTheme } from "@mantine/core"
import GameCard from "./GameCard"
import { DisplayedGameData } from "../../store/dashboardSlice"
import { RootState, wildcard } from "../../store/store"
import { useEffect, useState } from "react"
import { supabase } from "../../lib/Supabase"
import { logIn, logOut, updateJoinedAt, UserState } from "../../store/userSlice"
import { clearGame } from "../../store/gameSlice"
import { getCurrentUserInfo, getJoinedAt, isGoogleUser } from "../../api/user"
import { dbTables } from "../../constants/keys"
import AppContainer from "../common/containers/AppContainer"
import { addPlayerToGame, getGamesAsDisplayGames } from "../../api/games"
import { clearGameSelection } from "../../store/gameSelectionSlice"
import GamesHeader from "./GamesHeader"
import HomeHeader from "./HomeHeader"
import { filterOutNoUserGames, updateGames } from "../../store/dashboardSlice"
import OuterContentContainer from "../common/containers/OuterContentContainer"
import InnerContentContainer from "../common/containers/InnerContentContainer"
import HomeMenu from "./HomeMenu"
import { userImages } from "../../constants/images"
import { getRandom } from "../../functions/arrays"
import moment from "moment"
import { Action, removeActionByPayload } from "../../store/actionSlice"

export default function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useMantineTheme().colorScheme = "dark"

    const user: UserState = useSelector((state: RootState) => state.user)
    const dashboard = useSelector((state: RootState) => state.dashboard)
    const selection = useSelector((state: RootState) => state.gameSelection)
    const afterLoginActions: Action[] = useSelector((state: RootState) => state.actions.toExecuteAfterLogin)

    const [showMenu, setShowMenu] = useState<boolean>(false)

    const checkGoogleUsernameInputRedirect = async (username: wildcard) => {
        if ((await isGoogleUser()) && !username) {
            navigate("/username")
            return true
        }
    }

    const clearHomeState = () => {
        // not logged in, clear store
        dispatch(clearGame())
        dispatch(clearGameSelection())
        dispatch(logOut())
        // removes all games that have an id (real games, not games created in no user mode)
        dispatch(filterOutNoUserGames())
    }

    const loadOrSetJoinedAt = async (userId: string) => {
        let joinedAt = await getJoinedAt(userId)
        if (!joinedAt) {
            joinedAt = new Date().toISOString()
            await supabase.from(dbTables.profiles).update({ created_at: joinedAt }).eq("id", userId)
        }
        const formattedDate = moment(joinedAt).format("DD.MM.YYYY")
        dispatch(updateJoinedAt(formattedDate))
    }

    const executeAfterLoginActions = async (userId: string) => {
        for (const action of afterLoginActions) {
            console.log("Executing action: ", JSON.stringify(action, null, 2))
            console.log("UserId: ", userId)
            if (action.payload && userId) {
                await addPlayerToGame(userId, action.payload)
                dispatch(removeActionByPayload(action.payload))
            }
        }
    }

    useEffect(() => {
        ;(async () => {
            const { email, username, userId } = await getCurrentUserInfo()

            if (await checkGoogleUsernameInputRedirect(username)) return

            // not logged in
            if (!email || !username || !userId) {
                clearHomeState()
                return
            }

            // populate store
            await dispatch(
                logIn({
                    id: userId,
                    email: email,
                    username: username,
                })
            )
            await loadOrSetJoinedAt(userId)
            // clear play game data
            dispatch(clearGame())
            await executeAfterLoginActions(userId)
            // load games into storage
            const games = await getGamesAsDisplayGames(userId)
            if (games) dispatch(updateGames(games))
        })()
    }, [])

    const updateSource = async (sourceName: string, gameId: string) => {
        await supabase.from(dbTables.games).update({ image_name: sourceName }).eq("id", gameId)
    }

    const renderGames = () => {
        if (dashboard.games === null || dashboard.games.length === 0) {
            return (
                <div className={"w-full h-[500px] flex items-center justify-center"}>
                    <img
                        src={require("../../assets/images/empty-box.png")}
                        alt={"No games"}
                        className={"w-full max-w-[350px]"}
                    />
                </div>
            )
        }
        try {
            return dashboard.games.map((game: DisplayedGameData, index: number) => {
                if (game === null) return <></>
                let source = game.image_name
                if (!game.image_name) {
                    source = getRandom(userImages)
                    updateSource(source, game.id).then(() => {})
                }
                return (
                    <GameCard
                        name={game.name}
                        playerNames={game.players}
                        owner={game.owner}
                        isOwner={game.owner === user.username}
                        imgSource={require("../../assets/min/UserImages/" + source)}
                        onClick={() => handleCardClick(game.owner, game.id)}
                        id={game.id}
                        key={index}
                    />
                )
            })
        } catch (err) {
            return <></>
        }
    }

    const shouldRenderGames = selection.gameId === null && !showMenu

    const handleCardClick = (owner: string, id: string) => {
        if (!owner) {
            supabase
                .from(dbTables.games)
                .update({ owner: user.id })
                .eq("id", id)
                .then(() => {})
            return
        }
        navigate(`/game/${id}`)
    }

    return (
        <AppContainer>
            <HomeHeader show={showMenu} setShow={setShowMenu} />
            <OuterContentContainer>
                {!showMenu && <GamesHeader />}
                {showMenu && (
                    <div className={"w-full flex flex-row"}>
                        <p className={"text-white text-5xl font-bold text-center"}>Menu</p>
                    </div>
                )}
                <InnerContentContainer className={"mb-12"}>
                    {shouldRenderGames && renderGames()}
                    {showMenu && <HomeMenu />}
                </InnerContentContainer>
            </OuterContentContainer>
        </AppContainer>
    )
}
