import { ReactNode } from "react"
import { FormEvent } from "react"

interface AuthFormProps {
    className?: string
    children: ReactNode
}

export default function AuthForm(props: AuthFormProps) {
    return (
        <form
            className={`flex flex-col items-center w-5/6 mt-16 ${props.className}`}
            onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
        >
            {props.children}
        </form>
    )
}
