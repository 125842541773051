import AppContainer from "../common/containers/AppContainer"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router"
import { getCorrespondingGameId } from "../../api/smartInvite"
import { addToGameAfterLogin } from "../../store/actionSlice"
import { hostURL } from "../../constants/config"
import { showNotification } from "@mantine/notifications"
import { getGameData, getParticipantsFromGame } from "../../api/games"
import { RootState } from "../../store/store"
import { useEffect } from "react"

export default function SmartInvite() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const smartInviteId = params.smartInviteId

    const userId = useSelector((state: RootState) => state.user.id)

    useEffect(() => {
        if (!smartInviteId) return
        ;(async () => {
            const gameId = await getCorrespondingGameId(smartInviteId)
            if (!gameId) {
                showNotification({
                    title: "Error",
                    message: "Invalid smart invite",
                    color: "red",
                })
                navigate("/")
                return
            }
            if (!userId) {
                dispatch(addToGameAfterLogin(gameId))
                navigate("/login")
                return
            }
            const participants = await getParticipantsFromGame(gameId)
            if (participants && participants.map((p) => p.id).includes(userId)) {
                showNotification({
                    title: "Error",
                    message: "You are already in this game",
                    color: "red",
                })
                navigate("/")
                return
            }
            const gameData = await getGameData(gameId)
            if (gameData && gameData.owner === userId) {
                showNotification({
                    title: "Error",
                    message: "You are already the owner of this game",
                    color: "red",
                })
                navigate("/")
                return
            }
            dispatch(addToGameAfterLogin(gameId))
            navigate("/")
        })()
    }, [userId, smartInviteId])

    return (
        <AppContainer>
            <div className={"w-full h-full flex-center flex-col"}>
                <p className={"text-white font-bold text-4xl"}>Redirecting...</p>
                <p className={"text-white font-bold text-lg mt-6"}>
                    If you didn't get redirected, click{" "}
                    <a className={"text-white underline"} href={hostURL}>
                        here
                    </a>
                </p>
            </div>
        </AppContainer>
    )
}
