interface BigImageProps {
    src: string
    className?: string
}

export default function BigImage(props: BigImageProps) {
    return (
        <img
            src={props.src}
            alt={"big image"}
            className={`w-44 h-44 rounded-full border-white border-4 shadow-md ${props.className}`}
        />
    )
}
