import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type DisplayedGameData = {
    id: string
    name: string
    owner: string
    players: string[]
    image_name: string
}

export interface DashboardState {
    games: DisplayedGameData[]
    decks: any[]
    levels: any[]
    coins: number
}

const initialState: DashboardState = {
    games: [],
    coins: 0,
    decks: [],
    levels: [],
}

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
        addGame(state, action: PayloadAction<DisplayedGameData>) {
            state.games.push(action.payload)
        },
        updateGames(state, action: PayloadAction<DisplayedGameData[]>) {
            state.games = action.payload
        },
        removeGame(state, action: PayloadAction<string>) {
            state.games = state.games.filter((game) => game.id !== action.payload)
        },
        removeGames(state) {
            state.games = []
        },
        moveItemWithNameToFirstIfExistent(state, action: PayloadAction<string>) {
            const index = state.games.findIndex((game) => game.name.toUpperCase() === action.payload.toUpperCase())
            if (index !== -1) {
                const game = state.games[index]
                state.games.splice(index, 1)
                state.games.unshift(game)
            }
        },
        updateCoins(state, action: PayloadAction<number>) {
            state.coins = action.payload
        },
        filterOutNoUserGames(state) {
            state.games = state.games.filter((game) => game.id === "noUserMode")
        },
        updateDecksDisplayed(state, action: PayloadAction<any[]>) {
            state.decks = action.payload
        },
        updateLevelsDisplayed(state, action: PayloadAction<any[]>) {
            state.levels = action.payload
        },
    },
})

export const {
    addGame,
    updateGames,
    removeGame,
    filterOutNoUserGames,
    removeGames,
    updateCoins,
    updateDecksDisplayed,
    updateLevelsDisplayed,
} = dashboardSlice.actions

export default dashboardSlice.reducer
