import { combineReducers, configureStore } from "@reduxjs/toolkit"
import UserSlice, { logOut, UserState } from "./userSlice"
import { api } from "./middleware/api"
import GameSlice, { clearGame, GameState } from "./gameSlice"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist"
import GameSelectionSlice, { clearGameSelection, GameSelectionState } from "./gameSelectionSlice"
import DashboardSlice, { removeGames, DashboardState } from "./dashboardSlice"
import ActionSlice, { ActionState } from "./actionSlice"
import ChatSlice, { ChatState } from "./ChatSlice"

export type RootState = {
    game: GameState
    user: UserState
    gameSelection: GameSelectionState
    dashboard: DashboardState
    actions: ActionState
    chat: ChatState
}

export type wildcard = string | null | undefined

const reducers = combineReducers({
    user: UserSlice,
    game: GameSlice,
    gameSelection: GameSelectionSlice,
    dashboard: DashboardSlice,
    actions: ActionSlice,
    chat: ChatSlice,
})

const persistConfig = {
    key: "primary",
    storage,
}

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
        const middlewares = getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })

        return middlewares.concat(api)
    },
})

export const clearState = () => {
    const dispatch = store.dispatch
    dispatch(clearGame())
    dispatch(clearGameSelection())
    dispatch(logOut())
    // removes all games that have an id (real games, not games created in no user mode)
    dispatch(removeGames())
}

export const persistor = persistStore(store)
