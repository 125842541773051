import AppContainer from "../common/containers/AppContainer"
import { useNavigate, useParams } from "react-router"
import ClosableHeader from "../common/ClosableHeader"
import { DisplayDeck, getDecksFromDBAsDisplayDecks } from "../../api/decks"
import { useEffect, useState } from "react"
import { getRandom } from "../../functions/arrays"
import { systemImages } from "../../constants/images"
import ContentCard from "../common/cards/ContentCard"
import { clearGameSelection } from "../../store/gameSelectionSlice"
import { updateLevelNumber } from "../../store/gameSlice"
import { useDispatch } from "react-redux"

export default function DeckSelection() {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    const [displayDecks, setDisplayDecks] = useState<DisplayDeck[]>([])
    const [showError, setShowError] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            const decks = await getDecksFromDBAsDisplayDecks()
            if (!decks) {
                setShowError(true)
                return
            }
            setDisplayDecks(decks)
        })()
    }, [])

    const onClick = (deckId: string) => {
        const gameId = params.gameId
        navigate(`/game/${gameId}/deck/${deckId}`)
    }

    return (
        <AppContainer>
            <ClosableHeader
                onClickInject={() => {
                    dispatch(clearGameSelection())
                    dispatch(updateLevelNumber(null))
                }}
                className={"mb-10"}
            >
                Deck Selection
            </ClosableHeader>
            {displayDecks.map((deck: DisplayDeck, index: number) => {
                if (deck === null) return <></>
                let source = deck.image_name
                if (!deck.image_name) {
                    source = getRandom(systemImages)
                }
                return (
                    <ContentCard
                        name={deck.name}
                        description={deck.description}
                        imgSource={require("../../assets/min/SystemImages/" + source)}
                        onClick={() => onClick(deck.id)}
                        disableLoading={true}
                        key={index}
                    />
                )
            })}
            {showError && <img src={require("../../assets/images/404-not-found.png")} alt={"No decks found"} />}
        </AppContainer>
    )
}
