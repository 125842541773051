import { ReactNode } from "react"

interface WideButtonProps {
    title: string
    icon: ReactNode
    onClick: () => void
    className?: string
}

export default function WideButton(props: WideButtonProps) {
    return (
        <div
            className={`w-full flex flex-row items-center h-12 bg-primary-normal shadow-sm shadow-black rounded-md p-2 pl-4
            ${props.className} max-w-[400px] hover:cursor-pointer active:scale-95 transition-all duration-200`}
            onClick={props.onClick}
        >
            <div className={"flex flex-row items-center"}>
                <div className={"mr-2"}>{props.icon}</div>
                <p className={"text-white select-none font-bold text-lg mb-1"}>{props.title}</p>
            </div>
        </div>
    )
}
