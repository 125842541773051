export function isEmail(email: string): boolean {
    let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(email)
}

export function isValidGameName(gameName: string): string | null {
    if (gameName.trim().length === 0) {
        return "Game name cannot be empty"
    }
    if (gameName.length > 20) {
        return "Game name cannot be longer than 20 characters"
    }
    return null
}

export function isValidUsername(username: string): string | null {
    if (username.trim().length === 0) {
        return "Username cannot be empty"
    }
    if (username.length < 3) {
        return "Username cannot be shorter than 3 characters"
    }
    if (username.length > 15) {
        return "Username cannot be longer than 15 characters"
    }
    return null
}

export function isValidPassword(password: string): string | null {
    if (password.trim().length === 0) {
        return "Password cannot be empty"
    }
    if (password.length < 8) {
        return "Password cannot be shorter than 8 characters"
    }
    return null
}
