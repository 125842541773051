import AppContainer from "../common/containers/AppContainer"
import { TextInput } from "@mantine/core"
import ButtonWrapper from "../common/ButtonWrapper"
import { useState } from "react"
import { addUser, getCurrentEmail, getCurrentUsername, usernameAlreadyExists } from "../../api/user"
import { useNavigate } from "react-router"
import Header from "../common/Header";

export default function ChooseUsername() {
    const navigate = useNavigate()

    const [errorMessage, setErrorMessage] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)

    const validateUsername = async () => {
        setLoading(true)
        setUsername(username.trim())
        const takenUsername = await getCurrentUsername()
        if (takenUsername) navigate("/")
        if (!username) {
            setErrorMessage("Username cannot be empty")
            setLoading(false)
            return
        }
        if (username.length < 3) {
            setErrorMessage("Username must be at least 3 characters")
            setLoading(false)
            return
        }
        if (username.length > 20) {
            setErrorMessage("Username must be at most 20 characters")
            setLoading(false)
            return
        }
        const isTaken = await usernameAlreadyExists(username)
        if (isTaken) {
            setErrorMessage("Username already taken")
            setLoading(false)
            return
        }
        const email = await getCurrentEmail()
        if (!email) {
            setErrorMessage("Email not found")
            setLoading(false)
            return
        }
        const success = await addUser(email, username)
        if (success) navigate("/")
        else {
            setErrorMessage("Failed to add user")
            setLoading(false)
        }
    }

    return (
        <AppContainer>
            <div className={"flex flex-col items-center h-screen pt-20"}>
                <Header center={true}>Username</Header>
                <div className={"mt-2"}>
                    <p className={"text-center text-gray-500"}>Your username is your unique identifier on this site.</p>
                </div>
                <TextInput
                    className={"mt-12 w-full"}
                    placeholder={"Username"}
                    label="Username"
                    description="You can't change it, so choose it carefully"
                    value={username}
                    autoComplete={"off"}
                    size={"md"}
                    onChange={(e) => setUsername(e.currentTarget.value)}
                />
                {errorMessage && (
                    <div className={"mt-2"}>
                        <p className={"text-center text-red-500"}>{errorMessage}</p>
                    </div>
                )}
                <ButtonWrapper
                    onClick={validateUsername}
                    type={"submit"}
                    variant={"actionable"}
                    loading={loading}
                    className={"w-full rounded-2xl h-14 text-lg mt-4 mb-2"}
                >
                    Submit
                </ButtonWrapper>
            </div>
        </AppContainer>
    )
}
