import HomeMenuItem from "./HomeMenuItem"
import { IconBulb } from "@tabler/icons"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { useNavigate } from "react-router"

export default function HomeMenu() {
    const navigate = useNavigate()
    const loggedIn: boolean = useSelector((state: RootState) => state.user.loggedIn)

    return (
        <div className={"w-full h-full"}>
            <div className={"w-full flex flex-col mb-4"}>
                <p className={"text-3xl text-white font-bold mb-4 mt-6"}>Feedback</p>
                <div className={"ml-2 mr-2"}>
                    <HomeMenuItem
                        title={"Give Feedback"}
                        icon={<IconBulb size={33} strokeWidth={1.3} />}
                        onClick={() => navigate("/feedback")}
                        className={"mb-4"}
                        disabled={!loggedIn}
                    />
                </div>
            </div>
        </div>
    )
}
