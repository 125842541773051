interface PlayCardProps {
    currentQuestion: string
    size?: "small" | "large"
}

export default function PlayCard(props: PlayCardProps) {
    return (
        <div
            className={`w-11/12 ${props.size === "small" ? "h-28" : "h-56"} bg-primary-light ${
                props.size === "small" ? "rounded-xl" : "rounded-[70px]"
            } shadow-xl shadow-primary flex flex-row justify-center items-center ${
                props.size === "small" ? "p-2" : "p-8"
            } select-none`}
        >
            <p className={`text-white ${props.size === "small" ? "text-base" : "text-lg"} text-center`}>
                {props.currentQuestion}
            </p>
        </div>
    )
}
