import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { ChangeEvent, useState } from "react"
import { showNotification } from "@mantine/notifications"
import { addGame } from "../../store/dashboardSlice"
import { isValidGameName } from "../../functions/validation"
import { getRandom } from "../../functions/arrays"
import { userImages } from "../../constants/images"
import { supabase } from "../../lib/Supabase"
import { dbTables } from "../../constants/keys"
import { createSmartInvite, updateSmartInvite } from "../../api/smartInvite"
import AppContainer from "../common/containers/AppContainer"
import { TextInput } from "@mantine/core"
import ButtonWrapper from "../common/ButtonWrapper"
import ClosableHeader from "../common/ClosableHeader"
import AddUsers from "../common/AddUsers"
import { hostURL } from "../../constants/config"

export default function AddGame() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const user = useSelector((state: RootState) => state.user)

    const [gameName, setGameName] = useState<string>("")
    const [gameNameError, setGameNameError] = useState<string>("")
    const [users, setUsers] = useState<{ id: string; username: string }[]>([])
    const [smartInviteId, setSmartInviteId] = useState<string>("")

    const clearAndGoHome = () => {
        navigate("/")
        setGameName("")
        setUsers([])
    }

    const handleCreateGame = async () => {
        const gameNameError = isValidGameName(gameName)
        if (gameNameError) {
            setGameNameError(gameNameError)
            return
        }
        const randomImageName = getRandom(userImages)

        // no user mode
        if (!user.id) {
            dispatch(
                addGame({
                    id: "noUserMode",
                    name: gameName,
                    owner: "You",
                    players: [],
                    image_name: randomImageName,
                })
            )
            navigate("/")
            return
        }

        const { data, error } = await supabase
            .from(dbTables.games)
            .insert({
                name: gameName,
                owner: user.id,
                image_name: randomImageName,
            })
            .select()
        if (error || data.length === 0) {
            console.log(error)
            showNotification({
                title: "Error",
                message: "Game creation failed. Try again later.",
                color: "red",
            })
            return
        }

        const gameId = data[0].id
        const userIdsAndNames = [...users]

        const { error: error2 } = await supabase
            .from(dbTables.playerGameConnection)
            .insert(userIdsAndNames.map((value) => ({ game: gameId, player: value.id })))
        if (error2) {
            console.log(error2)
            showNotification({
                title: "Error",
                message: "Game creation failed. Try again later.",
                color: "red",
            })
            return
        }
        dispatch(
            addGame({
                id: gameId,
                name: gameName,
                owner: user.username ? user.username : "You",
                players: userIdsAndNames.map((value) => value.username),
                image_name: randomImageName,
            })
        )
        if (smartInviteId) {
            updateSmartInvite(smartInviteId, gameId)
                .then(() => {})
                .catch((err) => {
                    console.log("Error updating smart invite: ", err)
                })
        }
        clearAndGoHome()
    }

    const handleSmartInviteClick = async () => {
        if (!user.loggedIn) return
        if (smartInviteId) {
            await navigator.clipboard.writeText(`${hostURL}/smartInvite?id=${smartInviteId}`)
            return
        }
        const newSmartInviteId = await createSmartInvite()
        await navigator.clipboard.writeText(`${hostURL}/smartInvite?id=${newSmartInviteId}`)
        setSmartInviteId(newSmartInviteId)
    }

    return (
        <>
            <AppContainer>
                <ClosableHeader>Add Game</ClosableHeader>
                <TextInput
                    label="Game Name"
                    placeholder="Enter game name"
                    className={"mt-8"}
                    size={"md"}
                    radius={"lg"}
                    autoFocus
                    autoComplete={"off"}
                    error={gameNameError}
                    value={gameName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setGameName(e.target.value)
                        setGameNameError("")
                    }}
                />
                <AddUsers users={users} setUsers={setUsers} onSmartInviteClick={handleSmartInviteClick} />
                <div className={"w-full flex flex-row justify-end"}>
                    <ButtonWrapper onClick={handleCreateGame}>Create Game</ButtonWrapper>
                </div>
            </AppContainer>
        </>
    )
}
