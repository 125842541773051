import { colors } from "../../constants/colors"
import { useNavigate } from "react-router"
import { Burger } from "@mantine/core"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { getProfilePictureUrlOrRandom } from "../../api/user"
import { updateProfileImageName } from "../../store/userSlice"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { userImage } from "../../constants/images"

interface HomeHeaderProps {
    show: boolean
    setShow: (show: boolean) => void
}

export default function HomeHeader(props: HomeHeaderProps) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loggedIn = useSelector((state: RootState) => state.user.loggedIn)
    const image = useSelector((state: RootState) => state.user.profileImageName)
    const userId = useSelector((state: RootState) => state.user.id)

    useEffect(() => {
        if (userId) {
            getProfilePictureUrlOrRandom(userId).then((imageName) => {
                if (imageName && imageName !== image) dispatch(updateProfileImageName(imageName))
            })
        }
    }, [userId])

    const getImage = () => (image ? image : userImage)

    return (
        <div className={"w-full flex justify-between pl-1"}>
            <img
                src={require("../../assets/min/UserImages/" + getImage())}
                alt={"Profile picture"}
                className={`w-14 h-14 hover:cursor-pointer ${
                    loggedIn ? "rounded-tl-[65%] rounded-3xl" : "rounded-full"
                }`}
                onClick={() => navigate("/profile")}
            />
            {/*<div className={"flex flex-row items-center justify-center"}>*/}
            {/*    <IconCoin size={34} stroke={1.3} color={"#e7cc61"} />*/}
            {/*    <p className={"text-white text-lg font-bold ml-2 text-white"}>0</p>*/}
            {/*</div>*/}
            <div
                className={"flex justify-end items-center pr-1"}
                onClick={() => {
                    if (props.show) props.setShow(false)
                    else props.setShow(true)
                }}
            >
                <Burger opened={props.show} color={colors.accent} className={"hover:cursor-pointer"} />
            </div>
        </div>
    )
}
