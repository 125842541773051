import { IconLogout, IconSettings, IconTrash, IconX } from "@tabler/icons"
import React, { useState } from "react"
import OptionElement from "./OptionElement"
import { deleteGame, removePlayerFromGame } from "../../api/games"
import { showNotification } from "@mantine/notifications"
import { useDispatch, useSelector } from "react-redux"
import { Center } from "@mantine/core"
import { colors } from "../../constants/colors"
import { removeGame } from "../../store/dashboardSlice"
import CardCanvas from "../common/cards/CardCanvas"
import LoadingOval from "../common/LoadingOval"
import { RootState } from "../../store/store"
import CardImage from "../common/cards/CardImage"
import { useNavigate } from "react-router"

interface CardProps {
    id: string
    name: string
    playerNames: string[]
    owner: string
    isOwner: boolean
    imgSource: any
    onClick?: () => void
}

export default function GameCard(props: CardProps) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const userId = useSelector((state: RootState) => state.user.id)

    const [showSettings, setShowSettings] = useState<boolean>(false)
    const [deleteOption, setDeleteOption] = useState<boolean>(false)
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [paddingDisabled, setPaddingDisabled] = useState<boolean>(false)

    const handleDelete = async () => {
        if (props.id === "noUserMode") {
            dispatch(removeGame(props.id))
            return
        }
        const error = await deleteGame(props.id)
        if (error) {
            showNotification({
                title: "Error",
                message: error,
                color: "red",
            })
            setShowSettings(false)
            setDeleteOption(false)
            setDeleteLoading(false)
            return
        }
        dispatch(removeGame(props.id))
    }

    const handleLeave = async () => {
        if (props.id === "noUserMode") {
            dispatch(removeGame(props.id))
            return
        }
        if (!userId) return
        const error = await removePlayerFromGame(userId, props.id)
        if (error) {
            showNotification({
                title: "Error",
                message: error,
                color: "red",
            })
            setShowSettings(false)
            setDeleteOption(false)
            setDeleteLoading(false)
        }
        dispatch(removeGame(props.id))
    }

    const getCardContent = () => {
        if (deleteOption) {
            if (!paddingDisabled) setPaddingDisabled(true)
            return (
                <div className={"w-full h-full grid grid-rows-2 px-3 pt-2"}>
                    <Center>
                        <p className={"text-center text-white text-base font-bold ml-2 tablet:text-lg"}>
                            Are you sure, you want to {props.isOwner ? "delete" : "leave"} this game?
                        </p>
                    </Center>
                    <div className={"w-full grid grid-cols-2 pt-2"}>
                        <div
                            onClick={() => {
                                setDeleteOption(false)
                                setShowSettings(false)
                            }}
                            className={"w-full flex justify-center items-center pb-2 border-r-[1px]"}
                        >
                            <p className={"font-bold text-white pb-2"}>Cancel</p>
                        </div>
                        <div
                            onClick={() => {
                                setDeleteLoading(true)
                                if (props.isOwner)
                                    handleDelete().then(() => {
                                        setShowSettings(false)
                                        setDeleteOption(false)
                                        setDeleteLoading(false)
                                    })
                                else
                                    handleLeave().then(() => {
                                        setShowSettings(false)
                                        setDeleteOption(false)
                                        setDeleteLoading(false)
                                    })
                            }}
                            className={"w-full flex justify-center items-center pb-2 border-l-[1px]"}
                        >
                            {deleteLoading ? (
                                <LoadingOval />
                            ) : (
                                <p className={"font-bold text-error pb-2"}>{props.isOwner ? "Delete" : "Leave"}</p>
                            )}
                        </div>
                    </div>
                </div>
            )
        }

        if (showSettings) {
            if (!paddingDisabled) setPaddingDisabled(true)
            return (
                <div className={`w-full h-full grid ${props.isOwner ? "grid-cols-3" : "grid-cols-2"}`}>
                    <OptionElement
                        className={"bg-error rounded-l-3xl shadow-sm shadow-red"}
                        onClick={() => setDeleteOption(true)}
                    >
                        {props.isOwner ? (
                            <IconTrash size={50} stroke={1.2} color={colors.white} />
                        ) : (
                            <IconLogout size={50} stroke={1.2} color={colors.white} />
                        )}
                    </OptionElement>
                    {props.isOwner && (
                        <OptionElement
                            className={"bg-accent shadow-sm shadow-purple"}
                            onClick={() => navigate(`/gameOptions/${props.id}`)}
                        >
                            <IconSettings size={50} stroke={1.2} color={"#fff"} />
                        </OptionElement>
                    )}
                    <OptionElement
                        className={"bg-primary-normal rounded-r-3xl shadow-sm shadow-primary-normal"}
                        onClick={() => setShowSettings(false)}
                    >
                        <IconX size={50} stroke={1.2} color={colors.white} />
                    </OptionElement>
                </div>
            )
        }

        if (paddingDisabled) setPaddingDisabled(false)
        return (
            <div className={"flex flex-row items-center w-full"}>
                <CardImage src={props.imgSource} alt={"img"} />
                <div className={`grid grid-cols-4 items-center w-full h-full`}>
                    <div className={"h-full ml-4 col-span-3"} onClick={props.onClick}>
                        <p className={"text-xl font-bold text-white"}>{props.name}</p>
                        <p className={"text-[15px]"}>
                            <b>Owner:</b> {props.owner}
                        </p>
                        {props.playerNames.length > 0 && (
                            <p className={"text-[15px]"}>
                                <b>Players:</b> {props.playerNames.join(", ")}
                            </p>
                        )}
                    </div>
                    <div className={"flex justify-end items-center"}>
                        <IconSettings
                            className={`mr-4`}
                            color={colors.primaryBright}
                            size={40}
                            stroke={1.5}
                            onClick={() => setShowSettings(true)}
                        />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <CardCanvas disablePadding={paddingDisabled} onImageClick={props.onClick}>
            {getCardContent()}
        </CardCanvas>
    )
}
