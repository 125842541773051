import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"

export type DisplayDeck = {
    name: string
    description: string
    image_name: string
    id: string
}

export async function getDecksFromDBAsDisplayDecks(): Promise<DisplayDeck[] | null> {
    const { data, error } = await supabase.from(dbTables.decks).select("*")
    if (error) {
        console.log("Error while getting decks: " + error)
        return null
    }
    if (!data || data.length === 0) {
        console.log("No decks found")
        return null
    }
    return data.map((deck: any) => {
        return {
            name: deck.name,
            description: deck.description,
            image_name: deck.image_name,
            id: deck.id,
        }
    })
}
