import { ReactNode } from "react"

interface FeedbackContainerProps {
    children: ReactNode
    className?: string
}

export default function FeedbackContainer(props: FeedbackContainerProps) {
    return <div className={`flex flex-col justify-center items-center mt-20 ${props.className}`}>{props.children}</div>
}
