interface DividerProps {
    className?: string
}

export default function Divider(props: DividerProps) {
    return <hr className={`w-full border-white border-solid border-1 ${props.className}`} />
}
/*
className={`border border-solid border-2 ${props.className}´}
 */
