import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import PlayCard from "./PlayCard"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import { jumpToCard } from "../../store/gameSlice"
import { Swiper as SwiperClass } from "swiper/types"
import { isOwnerOfGame } from "../../api/games"
import { useEffect } from "react"
import { supabase } from "../../lib/Supabase"
import { RealtimeChannel } from "@supabase/supabase-js"
import { getCurrentCardIndexFromId } from "../../functions/cardCalculations"
import { useState } from "react"
import { updateIsOwner } from "../../store/gameSelectionSlice"
import { dbTables } from "../../constants/keys"

interface QuestionCardsProps {
    size: "small" | "large"
}

export default function QuestionCards(props: QuestionCardsProps) {
    const dispatch = useDispatch()
    const cardAnswers = useSelector((state: RootState) => state.game.cardQuestions)
    const currentCardIndex = useSelector((state: RootState) => state.game.currentCardIndex)
    const cards = useSelector((state: RootState) => state.game.cardIds)
    const gameId = useSelector((state: RootState) => state.gameSelection.gameId)
    const userId = useSelector((state: RootState) => state.user.id)
    const isOwnerOutside = useSelector((state: RootState) => state.gameSelection.isOwner)
    const level = useSelector((state: RootState) => state.gameSelection.levelId)
    const [swiper, setSwiper] = useState<SwiperClass | null>(null)

    useEffect(() => {
        if (!userId || !gameId) return
        let concurrentPlayingChannel: RealtimeChannel | null = null
        isOwnerOfGame(userId, gameId).then(async (isOwner) => {
            if (isOwner !== isOwnerOutside) dispatch(updateIsOwner(isOwner))
            if (isOwner) return

            concurrentPlayingChannel = supabase
                .channel("concurrent-playing")
                .on(
                    "postgres_changes",
                    {
                        event: "*",
                        schema: "public",
                        table: dbTables.levelGames,
                    },
                    (payload) => {
                        if (payload.eventType === "UPDATE" && payload.new.level === level) {
                            const currentCardIndex = getCurrentCardIndexFromId(cards, payload.new.selected_card)
                            dispatch(jumpToCard(currentCardIndex))
                        }
                    }
                )
                .subscribe()
        })

        return () => {
            concurrentPlayingChannel?.unsubscribe()
        }
    }, [])

    useEffect(() => {
        swiper?.slideTo(currentCardIndex)
    }, [currentCardIndex])

    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            initialSlide={currentCardIndex}
            className={`w-screen max-w-[550px] flex justify-center items-center`}
            onSlideChange={(swiper: SwiperClass) => dispatch(jumpToCard(swiper.activeIndex))}
            onSwiper={(swiper: SwiperClass) => {
                setSwiper(swiper)
                if (userId && gameId)
                    isOwnerOfGame(userId, gameId).then((isOwner) => {
                        if (!isOwner) {
                            swiper.allowTouchMove = false
                        }
                    })
            }}
        >
            {cardAnswers.map((card, index) => {
                return (
                    <SwiperSlide style={{ display: "flex" }} className={"justify-center items-center"} key={index}>
                        <PlayCard size={props.size} currentQuestion={card} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}
