export const dbTables = {
    cards: "cards",
    decks: "decks",
    games: "games",
    levelGames: "levelGames",
    levels: "levels",
    playerGameConnection: "playerGameConnection",
    profiles: "profiles",
    feedback: "feedback",
    smartInvites: "smartInvites",
    messages: "messages",
}
