import { ReactNode } from "react"
import CardImage from "./CardImage"

interface CardCanvasProps {
    children: ReactNode
    onImageClick?: () => void
    className?: string
    imgClassName?: string
    imgSrc?: any
    imgAlt?: string
    onClick?: () => void
    disablePadding?: boolean
}

export default function CardCanvas(props: CardCanvasProps) {
    return (
        <div
            className={`bg-primary-normal shadow-xl shadow-primary w-full min-h-28 rounded-3xl flex flex-row justify-between items-center select-none mb-8 ${
                props.disablePadding ? "" : "py-4"
            } ${props.className} hover:cursor-pointer overflow-hidden`}
            onClick={props.onClick}
        >
            {props.imgSrc && props.imgAlt && <CardImage src={props.imgSrc} alt={props.imgAlt} />}
            {props.children}
        </div>
    )
}
