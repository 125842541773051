import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { wildcard } from "./store"

export interface GameState {
    level: number | null
    levelGameId: wildcard
    currentCardIndex: number
    cardQuestions: string[]
    cardIds: string[]
}

const initialState: GameState = {
    level: null,
    levelGameId: null,
    currentCardIndex: 0,
    cardQuestions: [],
    cardIds: [],
}

export const gameSlice = createSlice({
    name: "game",
    initialState,
    reducers: {
        updateLevelNumber(state, action: PayloadAction<number | null>) {
            state.level = action.payload
        },
        updateCurrentCard(state, action: PayloadAction<number>) {
            state.currentCardIndex = action.payload
        },
        updateLevelGame(state, action: PayloadAction<wildcard>) {
            state.levelGameId = action.payload
        },
        updateCardIds(state, action: PayloadAction<any[]>) {
            state.cardIds = action.payload
        },
        updateCardQuestions(state, action: PayloadAction<string[]>) {
            state.cardQuestions = action.payload
        },
        nextCard(state) {
            if (state.currentCardIndex !== null) state.currentCardIndex = state.currentCardIndex + 1
        },
        previousCard(state) {
            if (state.currentCardIndex !== null) state.currentCardIndex = state.currentCardIndex - 1
        },
        jumpToCard(state, action: PayloadAction<number>) {
            state.currentCardIndex = action.payload
        },
        clearGame(state) {
            state.level = null
            state.levelGameId = null
            state.currentCardIndex = 0
            state.cardQuestions = []
            state.cardIds = []
        },
    },
})

export const {
    nextCard,
    previousCard,
    updateCardIds,
    updateLevelGame,
    clearGame,
    updateCardQuestions,
    jumpToCard,
    updateLevelNumber,
    updateCurrentCard,
} = gameSlice.actions

export default gameSlice.reducer
