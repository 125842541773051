interface NoticeProps {
    text: string
    linkText: string
    onClick: () => void
    align: "start" | "end"
}

export default function Notice(props: NoticeProps) {
    return (
        <div className={`flex flex-row w-full justify-${props.align} mt-2`}>
            <p>
                {props.text}{" "}
                <span className={"cursor-pointer underline"} onClick={props.onClick}>
                    {props.linkText}
                </span>
            </p>
        </div>
    )
}
