import AppContainer from "../common/containers/AppContainer"
import useLoginRequired from "../../hooks/useLoginRequired"
import React, { useState } from "react"
import FeedbackStars from "./FeedbackStars"
import FeedbackTextArea from "./FeedbackTextArea"
import { addFeedback } from "../../api/feedback"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { useNavigate } from "react-router"
import { showNotification } from "@mantine/notifications"
import FeedbackContainer from "./FeedbackContainer"
import FeedbackButton from "./FeedbackButton"
import ClosableHeader from "../common/ClosableHeader"

export default function Feedback() {
    useLoginRequired()
    const navigate = useNavigate()
    const userId = useSelector((state: RootState) => state.user.id)

    const [stars, setStars] = useState<number>(0)
    const [textAreaValue, setTextAreaValue] = useState<string>("")

    const submitFeedback = async () => {
        const success = await addFeedback(userId, stars, textAreaValue)
        if (success) {
            showNotification({
                title: "Feedback submitted",
                message: "Thank you for your feedback!",
                color: "#008080",
            })
        }
        navigate("/")
    }

    return (
        <AppContainer>
            <ClosableHeader>Feedback</ClosableHeader>
            <FeedbackContainer>
                <FeedbackStars setStarsValue={setStars} />
                <span className={"mt-8"} />
                <FeedbackTextArea setTextAreaValue={setTextAreaValue} textAreaValue={textAreaValue} />
                <FeedbackButton onClick={submitFeedback} disabled={!(textAreaValue || stars !== 0)} />
            </FeedbackContainer>
        </AppContainer>
    )
}
