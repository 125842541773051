export const colors = {
    primary: "#333762",
    primaryBackground: "#272a45",
    primaryLight: "#4d527b",
    primaryBright: "#6872bc",
    primaryDarkBackground: "#212339",
    // 7758ff
    accent: "#7a5dff",
    gray: "#1a1c1e",
    purple: "#7b4ce2",
    error: "#FF0000",
    hoveredWhite: "#f1f1f1",
    white: "#FFF",
    success: "#06b917",
}
