import ControlBar from "../play/ControlBar"
import QuestionCards from "../play/QuestionCards"
import ClosableHeader from "../common/ClosableHeader"
import { useNavigate } from "react-router"

export default function ChatHeader() {
    const navigate = useNavigate()
    return (
        <div className={"w-full sticky top-0 z-10 flex flex-col items-center justify-around pb-2 bg-primary-background"}>
            <ClosableHeader onClickReplace={() => navigate("/play")}>Chat</ClosableHeader>
            <QuestionCards size={"small"} />
            <ControlBar className={"mt-4"} size={"small"} />
        </div>
    )
}
