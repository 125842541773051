import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export enum ActionType {
    ADD_GAME = "ADD_GAME",
}

export interface Action {
    type: ActionType
    payload: any
}

export interface ActionState {
    toExecuteAfterLogin: Action[]
}

const initialState: ActionState = {
    toExecuteAfterLogin: [],
}

export const actionSlice = createSlice({
    name: "action",
    initialState,
    reducers: {
        addToGameAfterLogin(state, action: PayloadAction<string>) {
            state.toExecuteAfterLogin.push({ type: ActionType.ADD_GAME, payload: action.payload })
        },
        clearActionsAfterLogin(state) {
            state.toExecuteAfterLogin = []
        },
        removeActionByPayload(state, action: PayloadAction<string>) {
            state.toExecuteAfterLogin = state.toExecuteAfterLogin.filter((a) => a.payload !== action.payload)
        },
    },
})

export const { addToGameAfterLogin, removeActionByPayload, clearActionsAfterLogin } = actionSlice.actions
export default actionSlice.reducer
