import { ReactNode } from "react"

interface OwnChatMessageProps {
    children: ReactNode
    username: string
    img: string
}

export default function OwnChatMessage(props: OwnChatMessageProps) {
    const width = Math.min(window.innerWidth - 8, 542)
    return (
        <div className={"flex justify-end items-center flex-wrap mt-3 pr-2"} style={{ width: width }}>
            <div className={"ml-2 w-3/5 bg-primary-light rounded-2xl p-2 flex flex-col"}>
                <p className={"text-sm font-bold"}>{props.username}</p>
                <p className={"text-sm"} style={{ wordWrap: "break-word" }}>
                    {props.children}
                </p>
            </div>
            <img
                src={require("../../assets/min/UserImages/" + props.img)}
                alt={`${props.username} profile`}
                className={"w-10 h-10 rounded-full"}
            />
        </div>
    )
}
