import { supabase } from "../lib/Supabase"
import { wildcard } from "../store/store"
import { dbTables } from "../constants/keys"
import { getAndSetRandomUserImage } from "./images"

export async function getCurrentUserInfo(): Promise<{ email: wildcard; username: wildcard; userId: wildcard }> {
    const mail = await getCurrentEmail()
    const username = await getCurrentUsername()
    const id = await getCurrentUserId()
    return {
        email: mail,
        username,
        userId: id,
    }
}

export async function getProfilePictureUrlOrRandom(userId: string) {
    const imageName = await getProfilePicture(userId)
    if (imageName) return imageName

    return await getAndSetRandomUserImage((imageName: string) => {
        supabase
            .from(dbTables.profiles)
            .update({ profile_image_name: imageName })
            .eq("id", userId)
            .then(() => {})
    })
}

export async function getProfilePicture(userId: string) {
    const { data, error } = await supabase.from(dbTables.profiles).select("profile_image_name").eq("id", userId)
    if (error) return null
    if (!data || data.length <= 0 || data[0].profile_image_name === "" || data[0].profile_image_name === null)
        return null
    return data[0].profile_image_name
}

export async function getJoinedAt(userId: string) {
    const { data, error } = await supabase.from(dbTables.profiles).select("created_at").eq("id", userId)
    if (error) return null
    if (!data || data.length <= 0 || data[0].created_at === "" || data[0].created_at === null) return null
    return data[0].created_at
}

export const getCurrentEmail = async () => {
    const session = await supabase.auth.getSession()
    return session.data.session?.user.email
}

export const getCurrentUsername = async () => {
    const email = await getCurrentEmail()
    const { data, error } = await supabase.from("profiles").select("username").eq("email", email)
    if (error) return null
    if (data && data.length > 0) return data[0].username
    return null
}

export async function emailAlreadyExists(email: string): Promise<boolean> {
    const { data, error } = await supabase.from("profiles").select("*").eq("email", email)
    if (error) return true
    return data.length > 0
}

export async function usernameAlreadyExists(username: string): Promise<boolean> {
    const { data, error } = await supabase.from("profiles").select("*").eq("username", username)
    if (error) return true
    return data.length > 0
}

export async function getCurrentUserId() {
    const email = await getCurrentEmail()
    const { data, error } = await supabase.from("profiles").select("id").eq("email", email)
    if (error) return null
    if (data && data.length > 0) return data[0].id
    return null
}

export async function isGoogleUser() {
    const session = await supabase.auth.getSession()
    return session.data.session?.user.app_metadata.provider?.includes("google")
}

export async function addUser(email: string, username: string) {
    const { data, error } = await supabase.from("profiles").insert({ email, username }).select()
    if (error) return false
    return data?.length !== 0
}

export async function changeUsername(username: string): Promise<string | null> {
    const id = await getCurrentUserId()
    const { data, error } = await supabase.from("profiles").update({ username }).eq("id", id).select()
    if (error) return error.message
    return data?.length !== 0 ? null : "error"
}
