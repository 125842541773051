import { ReactNode } from "react"
import { IconChevronRight } from "@tabler/icons"

interface HomeMenuItem {
    title: string
    icon: ReactNode
    onClick: () => void
    disabled?: boolean
    className?: string
}

export default function HomeMenuItem(props: HomeMenuItem) {
    if (props.disabled) {
        return (
            <div
                className={`w-full flex flex-row justify-between items-center h-16 bg-disabledGray ${props.className} hover:cursor-pointer rounded-md p-2 pl-4`}
            >
                <div className={"flex flex-row items-center"}>
                    <div className={"mr-2 opacity-50"}>{props.icon}</div>
                    <p className={"select-none font-bold text-xl mb-1"}>{props.title}</p>
                </div>
                <IconChevronRight size={33} strokeWidth={1.3} />
            </div>
        )
    }

    return (
        <div
            className={`w-full flex flex-row justify-between items-center h-16
                bg-primary-normal shadow-sm shadow-black ${props.className} 
                rounded-md p-2 pl-4 hover:cursor-pointer active:scale-95
                transition-all duration-200
            `}
            onClick={() => {
                if (!props.disabled) props.onClick()
            }}
        >
            <div className={"flex flex-row items-center"}>
                <div className={"mr-2"}>{props.icon}</div>
                <p className={"text-white select-none font-bold text-xl mb-1"}>{props.title}</p>
            </div>
            <IconChevronRight size={33} strokeWidth={1.3} />
        </div>
    )
}
