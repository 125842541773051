import { nextCard, previousCard } from "../../store/gameSlice"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import ChevronButton from "./ChevronButton"
import { getCardsDone, getCardsLeft } from "../../functions/cardCalculations"
import { useState, useEffect } from "react"
import { isOwnerOfGame } from "../../api/games"
import {finishLevelGame} from "../../api/levelGames";

interface ControlBarProps {
    className?: string
    size: "small" | "large"
}

export default function ControlBar(props: ControlBarProps) {
    const dispatch = useDispatch()

    const [isOwner, setIsOwner] = useState<boolean>(true)

    const cardQuestions = useSelector((state: RootState) => state.game.cardQuestions)
    const currentCardIndex = useSelector((state: RootState) => state.game.currentCardIndex)
    const levelGameId = useSelector((state: RootState) => state.game.levelGameId)
    const loggedIn = useSelector((state: RootState) => state.user.loggedIn)
    const levelNumber = useSelector((state: RootState) => state.game.level)
    const userId = useSelector((state: RootState) => state.user.id)
    const gameId = useSelector((state: RootState) => state.gameSelection.gameId)

    useEffect(() => {
        if (userId && gameId) {
            isOwnerOfGame(userId, gameId).then((isOwner) => {
                setIsOwner(isOwner)
            })
        }
    }, [userId, gameId])

    return (
        <div className={`flex flex-row w-[90%] justify-between items-center ${props.className}`}>
            <ChevronButton
                direction={"left"}
                onClick={() => {
                    if (!isOwner) return;
                    const canGoPrevious = getCardsDone(cardQuestions, currentCardIndex).length > 0
                    if (canGoPrevious) dispatch(previousCard())
                }}
                size={props.size}
                disabled={!isOwner}
            />
            <p
                className={`font-bold mb-2 text-white select-none mt-2 ${
                    props.size === "large" ? "text-lg" : "text-base"
                }`}
            >
                LEVEL {levelNumber} - {1 + getCardsDone(cardQuestions, currentCardIndex).length} /{" "}
                {cardQuestions.length}
            </p>
            <ChevronButton
                direction={"right"}
                onClick={() => {
                    if (!isOwner) return
                    if (getCardsLeft(cardQuestions, currentCardIndex).length === 1 && loggedIn && levelGameId) {
                        finishLevelGame(levelGameId).then()
                    }
                    const canGoNext = getCardsLeft(cardQuestions, currentCardIndex).length > 0
                    if (canGoNext) dispatch(nextCard())
                }}
                size={props.size}
                disabled={!isOwner}
            />
        </div>
    )
}
