import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import Home from "./components/home"
import { Provider } from "react-redux"
import { persistor, store } from "./store/store"
import SignUp from "./components/authentication/SignUp"
import Login from "./components/authentication/LogIn"
import { MantineProvider } from "@mantine/core"
import { NotificationsProvider } from "@mantine/notifications"
import { PersistGate } from "redux-persist/integration/react"
import Play from "./components/play"
import PasswordReset from "./components/passwordReset"
import ChooseUsername from "./components/chooseUsername"
import RootContainer from "./components/common/containers/RootContainer"
import Profile from "./components/profile"
import AddGame from "./components/addGame"
import ReturnToHome from "./components/redirects/RedirectToHome"
import Feedback from "./components/feedback"
import SmartInvite from "./components/redirects/SmartInvite"
import { colors } from "./constants/colors"
import Chat from "./components/chat"
import DeckSelection from "./components/deckSelection"
import { LevelSelection } from "./components/levelSelection"
import GameOptions from "./components/gameOptions"

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/signUp",
        element: <SignUp />,
    },
    {
        path: "/game/:gameId",
        element: <DeckSelection />,
    },
    {
        path: "game/:gameId/deck/:deckId",
        element: <LevelSelection />,
    },
    {
        path: "/gameOptions/:gameId",
        element: <GameOptions />,
    },
    {
        path: "/play",
        element: <Play />,
    },
    {
        path: "/play/chat",
        element: <Chat />,
    },
    {
        path: "/resetPassword",
        element: <PasswordReset />,
    },
    {
        path: "/username",
        element: <ChooseUsername />,
    },
    {
        path: "/profile",
        element: <Profile />,
    },
    {
        path: "/addGame",
        element: <AddGame />,
    },
    {
        path: "/feedback",
        element: <Feedback />,
    },
    {
        path: "/smartInvite/:smartInviteId",
        element: <SmartInvite />,
    },
    {
        path: "*",
        element: <ReturnToHome />,
    },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MantineProvider
                theme={{
                    colorScheme: "dark",
                    colors: {
                        realRed: [
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                            "#FF0000",
                        ],
                        defaultColor: [
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                            "#333762",
                        ],
                    },
                    primaryColor: "defaultColor",
                    components: {
                        Modal: {
                            styles: {
                                modal: {
                                    backgroundColor: "#272a45",
                                    marginTop: 35,
                                    maxHeight: "70%",
                                    borderRadius: 15,
                                    overflow: "scroll",
                                },
                            },
                        },
                        InputWrapper: {
                            styles: {
                                label: { color: "white" },
                            },
                        },
                        TextInput: {
                            defaultProps: {
                                variant: "filled",
                                radius: "md",
                            },
                            styles: {
                                input: {
                                    backgroundColor: colors.primaryDarkBackground,
                                },
                            },
                        },
                        Textarea: {
                            defaultProps: {
                                variant: "filled",
                                radius: "md",
                            },
                            styles: {
                                input: {
                                    backgroundColor: colors.primaryDarkBackground,
                                },
                            },
                        },
                        PasswordInput: {
                            defaultProps: {
                                variant: "filled",
                                radius: "md",
                            },
                            styles: {
                                input: {
                                    backgroundColor: colors.primaryDarkBackground,
                                },
                            },
                        },
                    },
                }}
                withGlobalStyles
                withNormalizeCSS
            >
                <NotificationsProvider>
                    <RootContainer>
                        <RouterProvider router={router} />
                    </RootContainer>
                </NotificationsProvider>
            </MantineProvider>
        </PersistGate>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
