import { ReactNode, useEffect } from "react"

interface AppContainerProps {
    className?: string
    children?: ReactNode
    noPadding?: boolean
    center?: boolean
}

export default function AppContainer(props: AppContainerProps) {
    document.body.classList.add("bg-primary-darkBackground")
    document.body.style.overflow = "hidden"
    const width = window.innerWidth

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div
            className={`w-full max-w-[550px] ${
                width >= 550 ? "rounded-2xl" : ""
            } h-screen min-h-screen bg-primary-background ${
                props.noPadding ? "" : "p-4"
            } overflow-auto no-scrollbar-webkit no-scrollbar ${props.center ? "flex flex-col items-center" : ""} ${
                props.className
            }`}
        >
            {props.children}
        </div>
    )
}
