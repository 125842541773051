import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { wildcard } from "./store"
import { supabase } from "../lib/Supabase"
import { userImage } from "../constants/images"

export interface UserState {
    loggedIn: boolean
    id: wildcard
    email: wildcard
    username: wildcard
    joinedAt: wildcard
    profileImageName: wildcard
}

export type UserUpdate = {
    id: string
    email: string
    username: string
}

const initialState: UserState = {
    loggedIn: false,
    id: null,
    email: null,
    username: null,
    joinedAt: null,
    profileImageName: userImage,
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        logIn(user, action: PayloadAction<UserUpdate>) {
            user.loggedIn = true
            user.id = action.payload.id
            user.email = action.payload.email
            user.username = action.payload.username
        },
        updateUser(user, action: PayloadAction<UserUpdate>) {
            user.id = action.payload.id
            user.email = action.payload.email
            user.username = action.payload.username
        },
        updateJoinedAt(user, action: PayloadAction<string>) {
            user.joinedAt = action.payload
        },
        updateUsername(user, action: PayloadAction<string>) {
            user.username = action.payload
        },
        logOut(user) {
            user.loggedIn = false
            user.id = null
            user.email = null
            user.username = null
            user.profileImageName = userImage
            supabase.auth.signOut().then(() => {})
        },
        updateProfileImageName(user, action: PayloadAction<string>) {
            user.profileImageName = action.payload
        },
    },
})

export const { logIn, updateUsername, updateProfileImageName, logOut, updateJoinedAt, updateUser } = userSlice.actions

export default userSlice.reducer
