import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"
import { wildcard } from "../store/store"
import { getCurrentUserId } from "./user"

export async function addFeedback(userId: wildcard, star_rating: number, feedback: string) {
    let user = userId
    if (!user) user = await getCurrentUserId()
    const { error } = await supabase.from(dbTables.feedback).insert({
        user,
        star_rating,
        feedback_text: feedback,
    })
    if (error) {
        console.log(error)
        return false
    }
    return true
}
