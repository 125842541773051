import AppContainer from "../common/containers/AppContainer"
import { useNavigate } from "react-router"
import { useEffect } from "react"

export default function ReturnToHome() {
    const navigate = useNavigate()
    navigate("/")

    useEffect(() => {
        navigate("/")
    }, [navigate])

    return <AppContainer />
}
