import QuestionCards from "./QuestionCards"
import { useMantineTheme } from "@mantine/core"
import { useDispatch, useSelector } from "react-redux"
import ActionBar from "./ActionBar"
import ClosableHeader from "../common/ClosableHeader"
import ControlBar from "./ControlBar"
import { updateLevel } from "../../store/gameSelectionSlice"
import AppContainer from "../common/containers/AppContainer"
import { useNavigate } from "react-router"
import { RootState } from "../../store/store"

export default function Play() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useMantineTheme().colorScheme = "dark"

    const selection = useSelector((state: RootState) => state.gameSelection)

    return (
        <AppContainer noPadding={true} className={"flex flex-col justify-between pt-4"}>
            <ClosableHeader
                onClickReplace={() => {
                    dispatch(updateLevel(null))
                    navigate(`/game/${selection.gameId}/deck/${selection.deckId}`)
                }}
            >
                Play
            </ClosableHeader>
            <div className={"w-full flex flex-col justify-center items-center pb-20"}>
                <QuestionCards size={"large"} />
                <ControlBar size={"large"} className={"mt-6"} />
            </div>
            <div className={"w-full h-12"} />
            <ActionBar />
        </AppContainer>
    )
}
