import { ReactNode } from "react"

interface RoundIconButtonProps {
    children: ReactNode
    onClick: () => void
    className?: string
    size?: "small" | "large"
    disabled?: boolean
}

export default function RoundIconButton(props: RoundIconButtonProps) {
    return (
        <div
            className={`flex items-center justify-center ${
                props.size === "large" ? "w-16 h-16" : "w-12 h-12"
            } bg-primary-light rounded-[50%] shadow-lg shadow-primary transition-all duration-200 ${
                props.className
            }${props.disabled ? "" : "active:scale-95 active:shadow-none hover:cursor-pointer"}`}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}
