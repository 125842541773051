import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"
import { DisplayedGameData } from "../store/dashboardSlice"
import { destroySmartInviteWithGameId } from "./smartInvite"
import { deleteMessages } from "./chat"

export async function deleteGame(id: string): Promise<string | null> {
    const { error } = await supabase.from(dbTables.levelGames).delete().eq("game", id)
    if (error) {
        console.log(error)
        return error.message
    }
    const response = await supabase.from(dbTables.playerGameConnection).delete().eq("game", id)
    if (response.error) {
        console.log(response.error)
        return response.error.message
    }
    const response2 = await supabase.from(dbTables.games).delete().eq("id", id)
    if (response2.error) {
        console.log(response2.error)
        return response2.error.message
    }
    await destroySmartInviteWithGameId(id)
    await deleteMessages(id)
    return null
}

export type GameData = {
    id: string
    name: string
    owner: string
    image_name: string
}

export async function getGameData(gameId: string): Promise<GameData | null> {
    const { data, error } = await supabase.from(dbTables.games).select("id, name, owner, image_name").eq("id", gameId)
    if (error) {
        console.log(error)
        return null
    }
    return data[0]
}

async function getParticipatingGameIds(userId: string): Promise<string[]> {
    const playerGamesJoinResponse = await supabase.from(dbTables.playerGameConnection).select("*").eq("player", userId)
    if (playerGamesJoinResponse.error) {
        console.log("Error while fetching participation: " + playerGamesJoinResponse.error.message)
        return []
    }
    if (!playerGamesJoinResponse.data) {
        console.log("No participation found")
        return []
    }
    return playerGamesJoinResponse.data.map((participation) => participation.game)
}

async function getOwnedGameIds(userId: string) {
    const gamesResponse = await supabase.from(dbTables.games).select("*").eq("owner", userId)
    if (gamesResponse.error) {
        console.log("Error while fetching games: " + gamesResponse.error.message)
        return []
    }
    if (!gamesResponse.data) {
        console.log("No games found")
        return []
    }
    return gamesResponse.data.map((game) => game.id)
}

export async function getGamesAsDisplayGames(userId: string): Promise<DisplayedGameData[]> {
    const participatingGameIds: string[] = await getParticipatingGameIds(userId)
    const ownedGameIds = await getOwnedGameIds(userId)
    const gameIds = [...participatingGameIds, ...ownedGameIds]

    const displayGames: DisplayedGameData[] = []

    for (const gameId of gameIds) {
        const { data, error } = await supabase
            .from(dbTables.games)
            .select("*, ownerData:owner (username)")
            .eq("id", gameId)
            .single()
        if (error) continue
        const { data: data2, error: error2 } = await supabase
            .from(dbTables.playerGameConnection)
            .select(`*, player_name:player (username)`)
            .eq("game", gameId)
        if (error2) continue

        displayGames.push({
            id: data.id,
            name: data.name,
            owner: data.ownerData.username,
            players: data2.map((p) => p.player_name.username),
            image_name: data.image_name,
        })
    }
    return displayGames
}

export type ParticipantData = {
    id: string
    username: string
}

export async function getParticipantsFromGame(gameId: string): Promise<ParticipantData[] | null> {
    // get playerId and playerName from playerGamesJoin and profiles using a join query
    const { data, error } = await supabase
        .from(dbTables.playerGameConnection)
        .select(`*,playerData:player (id, username)`)
        .eq("game", gameId)
    if (error) {
        console.log(error)
        return null
    }
    return data.map((d) => ({ ...d.playerData }))
}

export async function addPlayerToGame(playerId: string, gameId: string) {
    const { error } = await supabase.from(dbTables.playerGameConnection).insert({ player: playerId, game: gameId })
    if (error) {
        console.log(error)
        return error.message
    }
    return null
}

export async function removePlayerFromGame(playerId: string, gameId: string) {
    const { error } = await supabase
        .from(dbTables.playerGameConnection)
        .delete()
        .eq("player", playerId)
        .eq("game", gameId)
    if (error) {
        console.log(error)
        return error.message
    }
    return null
}

export async function isOwnerOfGame(playerId: string, gameId: string) {
    const { data, error } = await supabase.from(dbTables.games).select("owner").eq("id", gameId)
    if (error) {
        console.log(error)
        return false
    }
    if (!data) {
        console.log("No game found")
        return false
    }
    return data[0].owner === playerId
}

export async function changeGameName(gameId: string, newName: string): Promise<string | null> {
    const { error } = await supabase.from(dbTables.games).update({ name: newName }).eq("id", gameId)
    if (error) {
        console.log(error)
        return error.message
    }
    return null
}
