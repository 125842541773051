import AppContainer from "../common/containers/AppContainer"
import useLoginRequired from "../../hooks/useLoginRequired"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { IconLogout } from "@tabler/icons"
import { colors } from "../../constants/colors"
import { clearState } from "../../store/store"
import { supabase } from "../../lib/Supabase"
import { useNavigate } from "react-router"
import Header from "../common/Header"
import { getProfilePictureUrlOrRandom, changeUsername, usernameAlreadyExists } from "../../api/user"
import { updateProfileImageName, updateUsername } from "../../store/userSlice"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { TextInput } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import ClosableHeader from "../common/ClosableHeader"
import BigImage from "../common/BigImage"
import WideButton from "../common/WideButton"

export default function Profile() {
    useLoginRequired()

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userId = useSelector((state: RootState) => state.user.id)
    const username = useSelector((state: RootState) => state.user.username)
    const email = useSelector((state: RootState) => state.user.email)
    const joinedAt = useSelector((state: RootState) => state.user.joinedAt)
    const image = useSelector((state: RootState) => state.user.profileImageName)

    const [changedUsername, setChangedUsername] = useState<string>(username || "")
    const [error, setError] = useState<string>("")

    useEffect(() => {
        if (userId) {
            getProfilePictureUrlOrRandom(userId).then((imageName) => {
                if (imageName && imageName !== image) dispatch(updateProfileImageName(imageName))
            })
        }
    }, [userId])

    const handleLogOut = () => {
        clearState()
        supabase.auth.signOut().then(() => {})
        navigate("/login")
    }

    const submitNewUsername = () => {
        ;(async () => {
            if (changedUsername === "") return
            if (changedUsername === username) return
            const usernameExists = await usernameAlreadyExists(changedUsername)
            if (usernameExists) {
                setError("Username already exists")
                return
            }
            const error = await changeUsername(changedUsername)
            if (error) {
                setError(error)
                return
            }
            dispatch(updateUsername(changedUsername))
            showNotification({
                title: "Username changed",
                message: "Your username has been changed successfully",
                color: "green",
            })
        })()
    }

    return (
        <AppContainer>
            <ClosableHeader onClickInject={submitNewUsername}>Profile</ClosableHeader>
            <div className={"flex justify-center items-center mt-6"}>
                <BigImage src={require("../../assets/min/UserImages/" + image)} />
            </div>
            <div className={"pl-6 pr-6 mt-12"}>
                <div className={"mb-10"}>
                    <div className={"text-white text-xl"}>
                        <Header size={3} center={false} className={"mb-2"}>
                            Personal Information
                        </Header>
                        <div className={"mb-2 flex flex-row items-center"}>
                            <span className={"font-bold mr-2"}>Username:</span>
                            <TextInput
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") submitNewUsername()
                                }}
                                className={"font-bold"}
                                value={changedUsername}
                                onChange={(e) => {
                                    setError("")
                                    setChangedUsername(e.currentTarget.value)
                                }}
                                error={error}
                            />
                        </div>
                        <p className={"mb-2"}>
                            <span className={"font-bold"}>Email:</span> {email}
                        </p>
                        <p>
                            <span className={"font-bold"}>Joined At:</span> {joinedAt}
                        </p>
                    </div>
                </div>
                <WideButton
                    title={"Log Out"}
                    icon={<IconLogout color={colors.white} />}
                    onClick={handleLogOut}
                    className={"mt-6"}
                />
            </div>
        </AppContainer>
    )
}
