import { Popover } from "@mantine/core"
import ButtonWrapper from "../common/ButtonWrapper"
import { useState } from "react"

interface SmartInviteButtonProps {
    onClick?: () => void
}

export default function SmartInviteButton(props: SmartInviteButtonProps) {
    const [popoverOpened, setPopoverOpened] = useState<boolean>(false)

    return (
        <Popover opened={popoverOpened} width={90} position={"top"} withArrow>
            <Popover.Target>
                <div>
                    <ButtonWrapper
                        onClick={() => {
                            if (props.onClick) {
                                props.onClick()
                            }
                            setPopoverOpened(true)
                            setTimeout(() => {
                                setPopoverOpened(false)
                            }, 1000)
                        }}
                    >
                        Invite
                    </ButtonWrapper>
                </div>
            </Popover.Target>
            <Popover.Dropdown>Copied</Popover.Dropdown>
        </Popover>
    )
}
