import { colors } from "../../constants/colors"
import { Oval } from "react-loader-spinner"
import React from "react"

interface LoadingOvalProps {
    width?: number
    height?: number
    color?: string
    secondaryColor?: string
    strokeWidth?: number
    strokeWidthSecondary?: number
}

export default function LoadingOval(props: LoadingOvalProps) {
    return (
        <Oval
            width={props.width || 22}
            height={props.height || 22}
            color={props.color || colors.primary}
            secondaryColor={props.secondaryColor || colors.primaryBright}
            strokeWidth={props.strokeWidth || 2.3}
            strokeWidthSecondary={props.strokeWidthSecondary || 2.3}
        />
    )
}
