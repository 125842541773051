import { PasswordInput, TextInput } from "@mantine/core"
import ButtonWrapper from "../common/ButtonWrapper"
import { supabase } from "../../lib/Supabase"
import { useState } from "react"
import { useNavigate } from "react-router"
import { emailAlreadyExists, usernameAlreadyExists } from "../../api/user"
import { dbTables } from "../../constants/keys"
import AppContainer from "../common/containers/AppContainer"
import { googleSignIn } from "../../api/auth"
import { isEmail } from "../../functions/validation"
import { isValidUsername } from "../../functions/validation"
import { isValidPassword } from "../../functions/validation"
import ClosableHeader from "../common/ClosableHeader"
import AuthForm from "./AuthForm"
import Notice from "./Notice"
import Divider from "../common/Divider"
import AuthenticationMethods from "./AuthenticationMethods"

export default function SignUp() {
    const navigate = useNavigate()

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<string>("")
    const [username, setUsername] = useState<string>("")
    const [usernameError, setUsernameError] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [passwordError, setPasswordError] = useState<string>("")

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const onSubmit = async () => {
        setIsLoading(true)
        setEmail(email.trim().toLowerCase())
        setUsername(username.trim())

        /* Validation */
        if (!isEmail(email)) {
            setEmailError("Invalid email")
            setIsLoading(false)
            return
        }
        const usernameNotValid = isValidUsername(username)
        if (usernameNotValid) {
            setUsernameError(usernameNotValid)
            setIsLoading(false)
            return
        }
        const passwordNotValid = isValidPassword(password)
        if (passwordNotValid) {
            setPasswordError(passwordNotValid)
            setIsLoading(false)
            return
        }
        if (await emailAlreadyExists(email)) {
            // If email already exists try signing in
            const { error } = await supabase.auth.signInWithPassword({
                email: email,
                password: password,
            })
            if (error) {
                setEmailError("Email already exists")
                setIsLoading(false)
            } else navigate("/")
            return
        }
        // Prevent duplicate usernames
        if (await usernameAlreadyExists(username)) {
            setUsernameError("Username already exists")
            setIsLoading(false)
            return
        }
        /* Validation */

        // username and email are free so SignUp
        const { error } = await supabase.auth.signUp({
            email: email,
            password: password,
            options: {
                data: {
                    username: username,
                },
            },
        })
        if (error) {
            setEmailError(error.message)
            setUsernameError(error.message)
            setPasswordError(error.message)
            setIsLoading(false)
            return
        }
        await supabase.from(dbTables.profiles).insert([{ username: username, email: email.toLowerCase() }])

        setIsLoading(false)
        setIsDisabled(true)
        setEmail("")
        setUsername("")
        setPassword("")
        navigate("/")
    }

    const googleLogIn = async () => {
        const error = await googleSignIn()
        if (error) {
            setEmailError(error.message)
            setUsernameError(error.message)
            setPasswordError(error.message)
            setIsLoading(false)
            return
        }
    }

    return (
        <AppContainer center={true}>
            <ClosableHeader>Sign Up</ClosableHeader>
            <AuthForm>
                <TextInput
                    label={"Email"}
                    placeholder={"john.doe@gmail.com"}
                    radius={"md"}
                    variant={"filled"}
                    autoFocus={true}
                    className={"w-full mb-6"}
                    value={email}
                    error={emailError}
                    onChange={(event) => {
                        setEmail(event.currentTarget.value)
                        setEmailError("")
                    }}
                />
                <TextInput
                    label={"Username"}
                    placeholder={"John"}
                    radius={"md"}
                    variant={"filled"}
                    className={"w-full mb-6"}
                    value={username}
                    error={usernameError}
                    onChange={(event) => {
                        setUsername(event.currentTarget.value)
                        setUsernameError("")
                    }}
                />
                <PasswordInput
                    label={"Password"}
                    placeholder={"********"}
                    radius={"md"}
                    variant={"filled"}
                    className={"w-full mb-6"}
                    value={password}
                    error={passwordError}
                    onChange={(event) => {
                        setPassword(event.currentTarget.value)
                        setPasswordError("")
                    }}
                />
                <ButtonWrapper
                    disabled={isDisabled}
                    loading={isLoading}
                    variant={"actionable"}
                    onClick={onSubmit}
                    type={"submit"}
                    className={"w-full rounded-2xl h-14 text-lg mt-4 mb-2"}
                >
                    Sign Up
                </ButtonWrapper>
                <Notice
                    text={"Already have an account?"}
                    linkText={"Log In"}
                    onClick={() => navigate("/logIn")}
                    align={"start"}
                />
                <Divider className={"mt-6"} />
                <AuthenticationMethods className={"mt-4"} googleLogIn={googleLogIn} isSignUp={true} />
            </AuthForm>
        </AppContainer>
    )
}
