import React from "react"

interface FeedbackTextAreaProps {
    setTextAreaValue: (value: string) => void
    textAreaValue: string
}

export default function FeedbackTextArea(props: FeedbackTextAreaProps) {
    return (
        <div className={"w-4/5"}>
            <p className={"font-bold text-2xl text-white mb-2"}>Write your thoughts here</p>
            <textarea
                className={
                    "w-full h-52 bg-gray-50 border-2 border-primary-background outline-none rounded-xl p-3 text-lg bg-primary-darkBackground text-white focus:ring-2 focus:ring-primary-normal focus:border-2 focus:border-primary-normal resize-none"
                }
                onChange={(e) => props.setTextAreaValue(e.target.value)}
            />
        </div>
    )
}
