import { ReactNode } from "react"

interface LogInMethodProps {
    children: ReactNode
    onClick: () => void
}

export default function AuthenticationMethod(props: LogInMethodProps) {
    return (
        <div
            className={
                "border-[1px] rounded-xl border-white h-12 w-16 flex justify-center items-center hover:cursor-pointer hover:bg-white transition-all duration-200"
            }
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}
