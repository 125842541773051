import { Dispatch, MiddlewareAPI } from "@reduxjs/toolkit"
import { supabase } from "../../lib/Supabase"
import { dbTables } from "../../constants/keys"
import { RootState } from "../store"

export const api = (api: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
    const handleAction: { [key: string]: (state: any) => void } = {
        "game/nextCard": (state: RootState) => {
            if (!state.game.currentCardIndex) return
            if (!state.gameSelection.isOwner) return
            const nextCardId = state.game.cardIds[state.game.currentCardIndex + 1]
            supabase
                .from(dbTables.levelGames)
                .update({ selected_card: nextCardId })
                .eq("id", state.game.levelGameId)
                .then(() => {})
        },
        "game/previousCard": (state: RootState) => {
            if (!state.game.currentCardIndex) return
            if (!state.gameSelection.isOwner) return
            const previousCardId = state.game.cardIds[state.game.currentCardIndex - 1]
            supabase
                .from(dbTables.levelGames)
                .update({ selected_card: previousCardId })
                .eq("id", state.game.levelGameId)
                .then(() => {})
        },
        "game/jumpToCard": (state: RootState) => {
            // Don't update card, if the update is coming from Realtime
            if (!state.gameSelection.isOwner) return
            const cardToJumpTo = state.game.cardIds[action.payload]
            supabase
                .from(dbTables.levelGames)
                .update({ selected_card: cardToJumpTo })
                .eq("id", state.game.levelGameId)
                .then(() => {})
        },
    }

    if (handleAction[action.type]) {
        const state = api.getState()
        handleAction[action.type](state)
    }
    return next(action)
}
