import { Textarea } from "@mantine/core"
import { useState, ChangeEvent, RefObject } from "react"
import { IconSend } from "@tabler/icons"
import { sendMessage } from "../../api/chat"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { showNotification } from "@mantine/notifications"

interface ChatTextAreaProps {
    className?: string
    reference?: RefObject<HTMLTextAreaElement>
    changeHeight?: () => void
}

export default function ChatTextArea(props: ChatTextAreaProps) {
    const userId = useSelector((state: RootState) => state.user.id)
    const gameId = useSelector((state: RootState) => state.gameSelection.gameId)

    const [value, setValue] = useState<string>("")

    const handleSend = async () => {
        if (value === "") return
        if (!userId || !gameId) {
            showNotification({
                title: "Error",
                message: "You are not logged in or you are not in a game.",
                color: "red",
            })
            return
        }
        const error = await sendMessage(userId, gameId, value)
        if (error) {
            showNotification({
                title: "Error",
                message: error,
                color: "red",
            })
            return
        }
        setValue("")
        props.changeHeight && props.changeHeight()
    }

    return (
        <form
            className={`w-full max-w-[550px] absolute bottom-0 z-10 p-3 flex items-center bg-primary-background tablet:rounded-b-2xl ${props.className}`}
        >
            <Textarea
                className={"w-full mr-2"}
                value={value}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                    // if enter is pressed, without shift being held, send the message
                    setValue(event.target.value)
                    if (props.changeHeight) props.changeHeight()
                }}
                minRows={1}
                maxRows={3}
                placeholder={"Type a message..."}
                autosize={true}
                ref={props.reference}
                onKeyDown={(event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                        event.preventDefault()
                        handleSend()
                    }
                }}
            />
            <IconSend size={25} strokeWidth={1.6} onClick={handleSend} />
        </form>
    )
}
