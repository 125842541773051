import { PasswordInput, TextInput, useMantineTheme } from "@mantine/core"
import { supabase } from "../../lib/Supabase"
import { useState } from "react"
import { useNavigate } from "react-router"
import ButtonWrapper from "../common/ButtonWrapper"
import { emailAlreadyExists, getCurrentUserId, getCurrentUsername } from "../../api/user"
import { useDispatch } from "react-redux"
import { logIn } from "../../store/userSlice"
import { showNotification } from "@mantine/notifications"
import AppContainer from "../common/containers/AppContainer"
import { googleSignIn } from "../../api/auth"
import ClosableHeader from "../common/ClosableHeader"
import Notice from "./Notice"
import Divider from "../common/Divider"
import AuthenticationMethods from "./AuthenticationMethods"
import { ChangeEvent } from "react"
import { isEmail } from "../../functions/validation"
import AuthForm from "./AuthForm"
import { hostURL } from "../../constants/config"

export default function Login() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useMantineTheme().colorScheme = "dark"

    const [email, setEmail] = useState<string>("")
    const [emailError, setEmailError] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [passwordError, setPasswordError] = useState<string>("")

    const onSubmit = async () => {
        if (!email) {
            setEmailError("Email is required")
            return
        }
        if (!password) {
            setPasswordError("Password is required")
            return
        }
        if (!isEmail(email)) {
            setEmailError("Invalid email")
            return
        }
        setIsLoading(true)
        const { error } = await supabase.auth.signInWithPassword({ email: email, password: password })
        if (error) {
            setEmailError(error.message)
            setPasswordError(error.message)
            setIsLoading(false)
            return
        }
        const username = await getCurrentUsername()
        const currentUserId = await getCurrentUserId()
        if (email && currentUserId && username) dispatch(logIn({ email: email, username: username, id: currentUserId }))
        navigate("/")
    }

    const handleReset = async () => {
        setEmailError("")
        setPasswordError("")
        if (!isEmail(email)) {
            setEmailError("Invalid email")
            setIsLoading(false)
            return
        }
        if (!(await emailAlreadyExists(email))) {
            setEmailError("Email does not exist")
            setIsLoading(false)
            return
        }
        setIsLoading(true)

        const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${hostURL}/resetPassword`,
        })
        if (error) {
            showNotification({
                title: "Error",
                message: error?.message,
                color: "realRed",
            })
            setIsLoading(false)
            return
        }
        setIsLoading(false)
        showNotification({
            title: "Success",
            message: "Check your email for a link to reset your password",
            color: "green",
        })
    }

    const googleLogIn = async () => {
        const error = await googleSignIn()
        if (error) {
            setEmailError(error.message)
            setPasswordError(error.message)
            setIsLoading(false)
            return
        }
    }

    return (
        <AppContainer center={true}>
            <ClosableHeader>Login</ClosableHeader>
            <AuthForm>
                <TextInput
                    label={"Email"}
                    placeholder={"name@mail.com"}
                    autoFocus={true}
                    className={"w-full mb-6"}
                    size={"md"}
                    error={emailError}
                    value={email}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setEmail(e.target.value)
                        setEmailError("")
                        setPasswordError("")
                    }}
                />
                <PasswordInput
                    label={"Password"}
                    placeholder={"******"}
                    className={"w-full"}
                    size={"md"}
                    error={passwordError}
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setPassword(e.target.value)
                        setEmailError("")
                        setPasswordError("")
                    }}
                />
                <ButtonWrapper
                    loading={isLoading}
                    onClick={onSubmit}
                    type={"submit"}
                    variant={"actionable"}
                    className={"w-full rounded-2xl h-14 text-lg mt-4 mb-2"}
                >
                    Login
                </ButtonWrapper>
                <Notice text={"Forgot your password?"} linkText={"Reset"} onClick={handleReset} align={"start"} />
                <Notice
                    text={"Don't have an STST account?"}
                    linkText={"Sign Up."}
                    onClick={() => navigate("/signUp")}
                    align={"start"}
                />
                <Divider className={"mt-6"} />
                <AuthenticationMethods className={"mt-4"} googleLogIn={googleLogIn} />
            </AuthForm>
        </AppContainer>
    )
}
