import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { RootState } from "../store/store"
import { useEffect } from "react"

export default function useLoginRequired() {
    const loggedIn = useSelector((state: RootState) => state.user.loggedIn)
    const navigate = useNavigate()

    useEffect(() => {
        if (!loggedIn) {
            navigate("/signUp")
        }
    }, [loggedIn])
}
