import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { useRef, useEffect, useState } from "react"
import { RootState } from "../../store/store"
import { getMessagesWithUser, supabaseChatMessageToChatMessage, SupabaseChatMessage, ChatMessage } from "../../api/chat"
import { RealtimeChannel } from "@supabase/supabase-js"
import { setMessages, addMessage } from "../../store/ChatSlice"
import { supabase } from "../../lib/Supabase"
import { dbTables } from "../../constants/keys"
import UserChatMessage from "./UserChatMessage"
import OwnChatMessage from "./OwnChatMessage"
import ChatTextArea from "./ChatTextArea"

export default function ChatBody() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const messageBodyRef = useRef<HTMLDivElement>(null)
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const userId = useSelector((state: RootState) => state.user.id)
    const gameId = useSelector((state: RootState) => state.gameSelection.gameId)
    const messages = useSelector((state: RootState) => state.chat.messages)

    const [innerSize, setInnerSize] = useState<number>(window.innerHeight)
    const [bottomPadding, setBottomPadding] = useState<number>(64)

    const height = innerSize - 280

    useEffect(() => {
        const handleResize = () => {
            setInnerSize(window.innerHeight)
        }
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    useEffect(() => {
        /*
        1. Load all messages from database
        2. Listen for new messages
        */
        let chatStreamingChannel: RealtimeChannel | null = null
        ;(async () => {
            if (!gameId) {
                navigate("/")
                return
            }
            const data = await getMessagesWithUser(gameId)
            if (!data) return
            dispatch(setMessages(data))
            chatStreamingChannel = supabase
                .channel("chat-streaming")
                .on(
                    "postgres_changes",
                    {
                        event: "INSERT",
                        schema: "public",
                        table: dbTables.messages,
                    },
                    async (payload) => {
                        const message = await supabaseChatMessageToChatMessage(payload.new as SupabaseChatMessage)
                        if (!message) return
                        dispatch(addMessage(message))
                    }
                )
                .subscribe()
        })()
        return () => {
            chatStreamingChannel?.unsubscribe()
        }
    }, [])

    // scroll to bottom when new message is added
    useEffect(() => {
        if (messageBodyRef.current) {
            messageBodyRef.current.scrollTop = messageBodyRef.current.scrollHeight
        }
    }, [messages])

    const addBottomPadding = () => {
        setTimeout(() => {
            const textAreaHeight = Math.min(textAreaRef?.current?.scrollHeight || 43, 85)
            setBottomPadding(textAreaHeight + 21)
            if (messageBodyRef.current) {
                messageBodyRef.current.scrollTop = messageBodyRef?.current?.scrollHeight
            }
        }, 12)
    }

    return (
        <div className={"flex flex-col w-full"}>
            {messages.length === 0 ? (
                <div className="flex flex-col items-center justify-center flex-grow">
                    <p className="text-white text-xl font-bold">No messages yet</p>
                    <p className="text-white text-lg">Send a message to start the conversation</p>
                </div>
            ) : (
                <div ref={messageBodyRef} className={"overflow-scroll"} style={{ maxHeight: height }}>
                    {messages.map((message: ChatMessage, i: number) => {
                        if (message.sender_id === userId) {
                            return (
                                <OwnChatMessage
                                    key={i}
                                    username={message.sender.username}
                                    img={message.sender.profile_image_name}
                                >
                                    {message.content}
                                </OwnChatMessage>
                            )
                        }
                        return (
                            <UserChatMessage
                                key={i}
                                username={message.sender.username}
                                img={message.sender.profile_image_name}
                            >
                                {message.content}
                            </UserChatMessage>
                        )
                    })}
                    <div style={{ height: bottomPadding }}></div>
                </div>
            )}
            <ChatTextArea changeHeight={addBottomPadding} reference={textAreaRef} />
        </div>
    )
}
