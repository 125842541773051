interface ActionBarButtonProps {
    icon: any
    onClick: () => void
    active: boolean
}

export default function ActionBarButton(props: ActionBarButtonProps) {
    return (
        <div
            className={`rounded-xl h-12 w-14 z-0 flex justify-center items-center hover:cursor-pointer ${
                props.active ? "bg-white" : ""
            }`}
            onClick={props.onClick}
        >
            {props.icon}
        </div>
    )
}
