import { IconHome } from "@tabler/icons"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router"
import { RootState } from "../../store/store"
import ActionBarButton from "./ActionBarButton"
import { IconMessageCircle2 } from "@tabler/icons"
import { useState } from "react"
import { IconClipboard } from "@tabler/icons"
import { IconClipboardCheck } from "@tabler/icons"
import { clearGameSelection } from "../../store/gameSelectionSlice"
import { colors } from "../../constants/colors"

export default function ActionBar() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const size = 35

    const cardQuestions = useSelector((state: RootState) => state.game.cardQuestions)
    const currentCardIndex = useSelector((state: RootState) => state.game.currentCardIndex)

    const [copyActive, setCopyActive] = useState<boolean>(false)

    return (
        <div className={"absolute bottom-0 left-50 w-full max-w-[550px] flex flex-row justify-start"}>
            <div
                className={`w-full sticky left-0 bottom-0 mb-8 flex flex-row items-center justify-around text-white mt-4`}
            >
                <ActionBarButton
                    icon={<IconHome size={size} color={"#fff"} />}
                    onClick={() => {
                        dispatch(clearGameSelection())
                        navigate("/")
                    }}
                    active={false}
                />
                <ActionBarButton
                    icon={<IconMessageCircle2 size={size} color={"#fff"} />}
                    onClick={() => {
                        navigate("/play/chat")
                    }}
                    active={false}
                />
                <ActionBarButton
                    icon={
                        copyActive ? (
                            <IconClipboardCheck
                                size={size}
                                className={"z-10 absolute top-50 left-50"}
                                color={colors.primary}
                            />
                        ) : (
                            <IconClipboard size={size} color={"#fff"} />
                        )
                    }
                    onClick={() => {
                        setCopyActive(true)
                        if (currentCardIndex === null || currentCardIndex === undefined) {
                            return
                        }
                        navigator.clipboard.writeText(cardQuestions[currentCardIndex]).then()
                        setTimeout(() => {
                            setCopyActive(false)
                        }, 700)
                    }}
                    active={copyActive}
                />
            </div>
        </div>
    )
}
