import Header from "./Header"
import { ReactNode } from "react"
import { colors } from "../../constants/colors"
import { useNavigate } from "react-router"
import { IconX } from "@tabler/icons"

interface ClosableHeaderProps {
    children: ReactNode
    onClickInject?: () => void
    onClickReplace?: () => void
    className?: string
}

export default function ClosableHeader(props: ClosableHeaderProps) {
    const navigation = useNavigate()
    return (
        <div className={`w-full flex flex-row justify-around items-center pl-4 pr-4 ${props.className}`}>
            <div className={"w-10"} />
            <Header center={true} size={4} className={"h-full mb-6 px-4"}>
                {props.children}
            </Header>
            <IconX
                size={40}
                className={"w-10 hover:cursor-pointer"}
                color={colors.white}
                onClick={() => {
                    if (props.onClickReplace) {
                        props.onClickReplace()
                        return
                    }
                    if (props.onClickInject) props.onClickInject()
                    navigation("/")
                }}
            />
        </div>
    )
}
