import AppContainer from "../common/containers/AppContainer"
import { useNavigate, useParams } from "react-router"
import ClosableHeader from "../common/ClosableHeader"
import { useEffect, useState } from "react"
import {
    addPlayerToGame,
    changeGameName,
    deleteGame,
    GameData,
    getGameData,
    getParticipantsFromGame,
    ParticipantData,
    removePlayerFromGame,
} from "../../api/games"
import { defaultImage } from "../../constants/images"
import BigImage from "../common/BigImage"
import { TextInput } from "@mantine/core"
import { isValidGameName } from "../../functions/validation"
import Header from "../common/Header"
import AddUsers from "../common/AddUsers"
import { showNotification } from "@mantine/notifications"
import WideButton from "../common/WideButton"
import { IconTrash } from "@tabler/icons"
import { createSmartInviteWithGameId, getCorrespondingSmartInviteId } from "../../api/smartInvite"
import { hostURL } from "../../constants/config"

export default function GameOptions() {
    const params = useParams()
    const navigate = useNavigate()
    const gameId = params.gameId

    const [gameData, setGameData] = useState<GameData | null>(null)
    const [participants, setParticipants] = useState<ParticipantData[]>([])
    const [gameName, setGameName] = useState<string>("")
    const [error, setError] = useState<string>("")
    const [image, setImage] = useState<string>(defaultImage)

    useEffect(() => {
        ;(async () => {
            if (!gameId) {
                navigate("/")
                return
            }
            const gameData = await getGameData(gameId)
            if (gameData) {
                setGameData(gameData)
                setImage(gameData.image_name)
                setGameName(gameData.name)
            }
            const participants = await getParticipantsFromGame(gameId)
            if (participants) setParticipants(participants)
        })()
    }, [gameId, navigate])

    const submitNewGameName = async () => {
        if (!gameData) return
        if (gameName === gameData.name) {
            setError("Game name cannot be the same as the current game name")
            return
        }
        const gameNameError = isValidGameName(gameName)
        if (gameNameError) {
            setError(gameNameError)
            return
        }
        const error = await changeGameName(gameData.id, gameName)
        if (error) {
            setError(error)
        }
    }

    const addUserInject = async (id: string, _: string) => {
        if (!gameData) return false
        const error = await addPlayerToGame(id, gameData.id)
        if (error) {
            showNotification({
                title: "Error",
                message: error,
                color: "red",
            })
            return false
        }
        return true
    }

    const removeUserInject = async (id: string, _: string) => {
        if (!gameData) return false
        const error = await removePlayerFromGame(id, gameData.id)
        if (error) {
            showNotification({
                title: "Error",
                message: error,
                color: "red",
            })
            return false
        }
        return true
    }

    const handleSmartInviteClick = async () => {
        if (!gameData) return
        const smartInviteId = await getCorrespondingSmartInviteId(gameData.id)
        if (smartInviteId) {
            await navigator.clipboard.writeText(hostURL + "/smartInvite/" + smartInviteId)
            return
        }
        const smartInviteIdNew = await createSmartInviteWithGameId(gameData.id)
        if (smartInviteIdNew) {
            await navigator.clipboard.writeText(hostURL + "/smartInvite/" + smartInviteIdNew)
            return
        }
    }

    return (
        <AppContainer center={true}>
            <ClosableHeader onClickInject={submitNewGameName}>Game Options</ClosableHeader>
            <BigImage src={require("../../assets/min/UserImages/" + image)} className={"mt-4"} />
            <div className={"w-full flex flex-row items-center mt-10"}>
                <span className={"font-bold mr-2"}>Game Name:</span>
                <TextInput
                    onKeyDown={(e) => {
                        if (e.key === "Enter") submitNewGameName().then()
                    }}
                    className={"font-bold"}
                    value={gameName}
                    onChange={(e) => {
                        setError("")
                        setGameName(e.currentTarget.value)
                    }}
                    error={error}
                />
            </div>
            <Header className={"mt-4"} size={4}>
                Participants
            </Header>
            <div className={"w-full"}>
                <AddUsers
                    users={participants}
                    setUsers={setParticipants}
                    addUserInject={addUserInject}
                    handleRemoveUserInject={removeUserInject}
                    onSmartInviteClick={handleSmartInviteClick}
                />
            </div>
            <div className={"w-full flex flex-row justify-start"}>
                <WideButton
                    title={"Delete"}
                    icon={<IconTrash />}
                    onClick={async () => {
                        if (!gameData) return
                        await deleteGame(gameData.id)
                        navigate("/")
                    }}
                    className={"bg-red-600 shadow-red-600 max-w-[550px] mt-10 mb-20"}
                />
            </div>
        </AppContainer>
    )
}
