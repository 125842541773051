import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"

export async function sendMessage(senderId: string, gameId: string, content: string): Promise<string | null> {
    const { error } = await supabase.from(dbTables.messages).insert({
        sender_id: senderId,
        game_id: gameId,
        content: content,
    })
    if (error) {
        console.log(error)
        return error.message
    }
    return null
}

export async function deleteMessages(gameId: string): Promise<string | null> {
    const { error } = await supabase.from(dbTables.messages).delete().eq("game_id", gameId)
    if (error) {
        console.log(error)
        return error.message
    }
    return null
}

export type ChatMessage = {
    id: string
    sender_id: string
    game_id: string
    content: string
    created_at: string
    sender: {
        id: string
        username: string
        profile_image_name: string
    }
}

export type SupabaseChatMessage = {
    content: string
    created_at: string
    game_id: string
    id: string
    sender_id: string
}

export async function getMessagesWithUser(gameId: string): Promise<ChatMessage[] | null> {
    const { data, error } = await supabase
        .from(dbTables.messages)
        .select(
            `
            *,
            sender:sender_id (id, username, profile_image_name)
            `
        )
        .eq("game_id", gameId)
        .order("created_at", { ascending: true })
    if (error) {
        console.log(error)
        return null
    }
    if (data === null) return null
    const newData: ChatMessage[] = []
    data.forEach((message: any) => {
        newData.push(message as ChatMessage)
    })
    return newData
}

export async function supabaseChatMessageToChatMessage(message: SupabaseChatMessage): Promise<ChatMessage | null> {
    // add sender (id, username, profile_image_name)
    const { data, error } = await supabase
        .from(dbTables.profiles)
        .select("id, username, profile_image_name")
        .eq("id", message.sender_id)
        .single()
    if (error) {
        console.log(error)
        return null
    }
    if (data === null) return null
    return {
        id: message.id,
        sender_id: message.sender_id,
        game_id: message.game_id,
        content: message.content,
        created_at: message.created_at,
        sender: {
            id: data.id,
            username: data.username,
            profile_image_name: data.profile_image_name,
        },
    }
}
