import { wildcard } from "./store"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface GameSelectionState {
    gameId: wildcard
    deckId: wildcard
    levelId: wildcard
    isOwner: boolean
}

const initialState: GameSelectionState = {
    gameId: null,
    deckId: null,
    levelId: null,
    isOwner: false,
}

export const gameSelectionSlice = createSlice({
    name: "gameSelection",
    initialState,
    reducers: {
        updateGame(state: GameSelectionState, action: PayloadAction<wildcard>) {
            state.gameId = action.payload
        },
        updateDeck(state: GameSelectionState, action: PayloadAction<wildcard>) {
            state.deckId = action.payload
        },
        updateLevel(state: GameSelectionState, action: PayloadAction<wildcard>) {
            state.levelId = action.payload
        },
        updateIsOwner(state: GameSelectionState, action: PayloadAction<boolean>) {
            state.isOwner = action.payload
        },
        clearGameSelection(state) {
            state.gameId = null
            state.deckId = null
            state.levelId = null
        },
    },
})

export const { updateGame, updateIsOwner, updateDeck, updateLevel, clearGameSelection } = gameSelectionSlice.actions

export default gameSelectionSlice.reducer
