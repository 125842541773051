import CardCanvas from "./CardCanvas"
import { Center } from "@mantine/core"
import LoadingOval from "../LoadingOval"
import { useState } from "react"
import { colors } from "../../../constants/colors"

interface LevelCardProps {
    name: string
    description: string
    imgSource?: string
    onClick?: () => void
    disableLoading?: boolean
}

export default function ContentCard(props: LevelCardProps) {
    const [loading, setLoading] = useState<boolean>(false)

    const handleClick = () => {
        if (!props.disableLoading) setLoading(true)
        if (props.onClick) props.onClick()
    }

    return (
        <CardCanvas imgSrc={props.imgSource} imgAlt={"img"} onClick={handleClick} className={"animate-slideInFromLeft"}>
            {loading && (
                <Center className={"w-full h-full"}>
                    <LoadingOval
                        width={75}
                        height={75}
                        color={colors.primaryBright}
                        secondaryColor={colors.primaryLight}
                    />
                </Center>
            )}
            {!loading && (
                <div className={"w-full h-full flex flex-col ml-2"} onClick={handleClick}>
                    <p className={"font-bold text-white text-xl"}>{props.name}</p>
                    <p className={"text-[15px]"}>{props.description}</p>
                </div>
            )}
        </CardCanvas>
    )
}
