import { ChangeEvent, FormEvent, useState } from "react"
import { TextInput } from "@mantine/core"
import SmartInviteButton from "../addGame/SmartInviteButton"
import ButtonWrapper from "./ButtonWrapper"
import RemovableUserItem from "../addGame/RemovableUserItem"
import { isEmail } from "../../functions/validation"
import { supabase } from "../../lib/Supabase"
import { dbTables } from "../../constants/keys"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

interface AddUsersProps {
    users: { id: string; username: string }[]
    setUsers: (users: { id: string; username: string }[]) => void
    addUserInject?: (id: string, username: string) => Promise<boolean>
    onSmartInviteClick?: () => void
    handleRemoveUserInject?: (id: string, username: string) => Promise<boolean>
}

export default function AddUsers(props: AddUsersProps) {
    const user = useSelector((state: RootState) => state.user)
    const [currentUserInput, setCurrentUserInput] = useState<string>("")
    const [currentUserError, setCurrentUserError] = useState<string>("")

    const handleUserRemove = (index: number) => {
        const values = [...props.users]
        values.splice(index, 1)
        props.setUsers(values)
    }

    const validateAndGetUser = async (username: string): Promise<{ id: string; errorMessage: string }> => {
        if (username.trim().length === 0) {
            return { id: "", errorMessage: "Username cannot be empty" }
        }
        for (let i = 0; i < props.users.length; i++) {
            if (props.users[i].username === username) {
                return { id: "", errorMessage: "User already added" }
            }
        }

        if (isEmail(username)) {
            // check if email exists, and return id, and username
            if (username === user.email) return { id: "", errorMessage: "You can't share the game with yourself" }
            const { data, error } = await supabase.from(dbTables.profiles).select("id").eq("email", username)
            if (error) {
                return { id: "", errorMessage: error.message }
            }
            if (data.length === 0) {
                return { id: "", errorMessage: "User does not exist" }
            }
            for (let i = 0; i < props.users.length; i++) {
                if (props.users[i].id === data[0].id) {
                    return { id: "", errorMessage: "User already added" }
                }
            }
            return { id: data[0].id, errorMessage: "" }
        }

        // check if username exists, and return id, and username
        if (username === user.username) {
            return { id: "", errorMessage: "You can't share the game with yourself" }
        }
        const { data, error } = await supabase.from(dbTables.profiles).select("id").eq("username", username)
        if (error) {
            return { id: "", errorMessage: error.message }
        }
        if (data.length === 0) {
            return { id: "", errorMessage: "User does not exist" }
        }
        return { id: data[0].id, errorMessage: "" }
    }

    return (
        <>
            <form onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}>
                <TextInput
                    label="Username / Email"
                    placeholder="Enter username or email"
                    className={"mt-8"}
                    size={"md"}
                    radius={"lg"}
                    autoComplete={"off"}
                    error={currentUserError}
                    value={currentUserInput}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrentUserInput(e.target.value)
                        setCurrentUserError("")
                    }}
                />
                <div className={"w-full flex flex-row justify-between mt-6"}>
                    <SmartInviteButton onClick={props.onSmartInviteClick} />
                    <ButtonWrapper
                        className={"w-[7rem]"}
                        onClick={async () => {
                            const { id, errorMessage } = await validateAndGetUser(currentUserInput)
                            if (errorMessage) {
                                setCurrentUserError(errorMessage)
                                setCurrentUserInput("")
                                return
                            }
                            if (props.addUserInject) {
                                const canProceed = await props.addUserInject(id, currentUserInput)
                                if (!canProceed) return
                            }
                            const values = [...props.users]
                            values.push({ id: id, username: currentUserInput })
                            props.setUsers(values)
                            setCurrentUserInput("")
                        }}
                        type={"submit"}
                    >
                        Add User
                    </ButtonWrapper>
                </div>
            </form>
            <div className={"w-full pr-2 pl-2 mt-6 mb-6"}>
                {props.users.map((user, index) => (
                    <RemovableUserItem
                        key={index}
                        number={index + 1}
                        onRemove={async () => {
                            if (props.handleRemoveUserInject) {
                                const canProceed = await props.handleRemoveUserInject(user.id, user.username)
                                if (!canProceed) return
                            }
                            handleUserRemove(index)
                        }}
                    >
                        {user.username}
                    </RemovableUserItem>
                ))}
            </div>
        </>
    )
}
