import React from "react"
// @ts-ignore
import ReactStars from "react-rating-stars-component"

interface FeedbackStarProps {
    setStarsValue: (value: number) => void
}

export default function FeedbackStars(props: FeedbackStarProps) {
    return (
        <div className={"flex flex-col justify-center items-center"}>
            <p className={"font-bold text-center text-2xl text-white mb-2"}>What do you think of STST?</p>
            <ReactStars
                count={5}
                size={38}
                onChange={(newRating: number) => props.setStarsValue(newRating)}
                activeColor="#ffd700"
            />
        </div>
    )
}
