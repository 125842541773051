export const systemImages = [
    "BuildingBlocks.png",
    "BusinessFuture.png",
    "CloudyHeart.png",
    "Connection.png",
    "FutureHouse.png",
    "IllustratedCouple.png",
    "Perception.png",
    "RedRose.png",
    "ReflectionSimple.png",
    "Soulmates.png",
    "SpaceLadder.png",
    "default.png",
    "user.png",
]

export const userImages = [
    "BlueBrain.png",
    "DwarfGoBr.png",
    "FutureCity.png",
    "LowPolyShip.png",
    "LowPolyTree.png",
    "Relationship.jpeg",
    "SmokingDefault.png",
]

export const defaultImage = "FullTransparent.png"
export const userImage = "user.png"
