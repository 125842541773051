import AppContainer from "../common/containers/AppContainer"
import ClosableHeader from "../common/ClosableHeader"
import { useNavigate, useParams } from "react-router"
import { useEffect, useState } from "react"
import { DisplayLevel, getLevelsFromDBAsDisplayLevels } from "../../api/levels"
import { getRandom } from "../../functions/arrays"
import { systemImages } from "../../constants/images"
import ContentCard from "../common/cards/ContentCard"
import { clearGameSelection, updateDeck, updateGame, updateLevel } from "../../store/gameSelectionSlice"
import { updateLevelNumber } from "../../store/gameSlice"
import { initialiseGame, initialiseNoUserModeGame } from "../../utils/initialiseGame"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"

export function LevelSelection() {
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()
    const deckId = params.deckId

    const user = useSelector((state: RootState) => state.user)

    const [displayLevels, setDisplayLevels] = useState<DisplayLevel[]>([])
    const [showError, setShowError] = useState<boolean>(false)

    useEffect(() => {
        ;(async () => {
            if (!deckId) {
                setShowError(true)
                return
            }
            const levels = await getLevelsFromDBAsDisplayLevels(deckId)
            if (!levels) {
                setShowError(true)
                return
            }
            setDisplayLevels(levels)
        })()
    }, [])

    return (
        <AppContainer>
            <ClosableHeader
                onClickInject={() => {
                    dispatch(clearGameSelection())
                    dispatch(updateLevelNumber(null))
                }}
                className={"mb-10"}
            >
                Level Selection
            </ClosableHeader>
            {displayLevels.map((level: DisplayLevel, index: number) => {
                if (level === null) return <></>
                if (deckId !== level.deck_id) return null
                let source = level.image_name
                if (!level.image_name) {
                    source = getRandom(systemImages)
                }
                return (
                    <ContentCard
                        name={"Level " + level.level_number}
                        description={level.description}
                        imgSource={require("../../assets/min/SystemImages/" + source)}
                        onClick={() => {
                            dispatch(updateGame(params.gameId))
                            dispatch(updateDeck(deckId))
                            dispatch(updateLevel(level.id))
                            dispatch(updateLevelNumber(level.level_number))
                            if (!user.loggedIn) initialiseNoUserModeGame(navigate).then(() => {})
                            else initialiseGame(navigate).then(() => {})
                        }}
                        key={index}
                    />
                )
            })}
            {showError && <img src={require("../../assets/images/404-not-found.png")} alt={"No levels found"} />}
        </AppContainer>
    )
}
