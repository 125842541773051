import { showNotification } from "@mantine/notifications"
import { getLevelGames } from "../api/levelGames"
import { getCards } from "../api/cards"
import { updateCardIds, updateCardQuestions, updateCurrentCard, updateLevelGame } from "../store/gameSlice"
import { cardObjectsToIds, cardObjectsToQuestions, getCurrentCardIndexFromId } from "../functions/cardCalculations"
import { supabase } from "../lib/Supabase"
import { dbTables } from "../constants/keys"
import { GameSelectionState } from "../store/gameSelectionSlice"
import { store } from "../store/store"

const dispatch = store.dispatch

export async function initialiseGame(navigate: Function) {
    const selection: GameSelectionState = store.getState().gameSelection
    if (!selection.gameId || !selection.deckId || !selection.levelId) {
        showNotification({
            title: "Error",
            message: "Error while initialising game. Reload the page and try again.",
            color: "red",
        })
        return
    }

    const existingLevelGames = await getLevelGames(selection.gameId, selection.deckId, selection.levelId)
    if (!existingLevelGames) {
        showNotification({
            title: "Error",
            message: "Error while checking for existing levelGame",
            color: "red",
        })
        return
    }

    let cards = await getCards(selection.levelId)
    if (!cards) {
        showNotification({
            title: "Error",
            message: "Error while getting cards",
            color: "red",
        })
        return
    }
    dispatch(updateCardIds(cardObjectsToIds(cards)))

    if (existingLevelGames && existingLevelGames.length > 0) {
        await initialiseGameLevelGameFound(existingLevelGames, cards, navigate)
        return
    }

    await initialiseGameLevelGameNotFound(cards, navigate)
}

export async function initialiseNoUserModeGame(navigate: Function) {
    const selection: GameSelectionState = store.getState().gameSelection
    // no user mode dispatch cards for this level
    if (!selection.levelId) return
    let cards = await getCards(selection.levelId)
    if (!cards) {
        showNotification({
            title: "Error",
            message: "No cards found for this level",
            color: "red",
        })
        return
    }
    dispatch(updateCardQuestions(cardObjectsToQuestions(cards)))
    dispatch(updateCardIds(cardObjectsToIds(cards)))
    navigate("/play")
    return
}

export async function initialiseGameLevelGameFound(existingLevelGames: any[], cards: any[], navigate: Function) {
    await dispatch(updateLevelGame(existingLevelGames[0].id))
    const selectedCardIndex = getCurrentCardIndexFromId(cardObjectsToIds(cards), existingLevelGames[0].selected_card)
    await dispatch(updateCardQuestions(cardObjectsToQuestions(cards)))
    await dispatch(updateCurrentCard(selectedCardIndex))
    navigate(`/play`)
}

export async function initialiseGameLevelGameNotFound(cards: any[], navigate: Function) {
    const selection: GameSelectionState = store.getState().gameSelection
    const { data, error } = await supabase
        .from(dbTables.levelGames)
        .insert([
            {
                deck: selection.deckId,
                level: selection.levelId,
                game: selection.gameId,
                selected_card: null,
                finished: false,
            },
        ])
        .select()
    if (error) {
        console.log(`Error while creating levelGame: ${error.message}`)
        showNotification({
            title: "Error",
            message: "Error while creating levelGame",
            color: "red",
        })
        return
    }

    await dispatch(updateLevelGame(data[0].id))
    setTimeout(() => {
        if (!cards) return
        dispatch(updateCardQuestions(cardObjectsToQuestions(cards)))
        navigate(`/play`)
    }, 1000)
}
